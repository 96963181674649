import LanguageSelector from './footer/LanguageSelector';
import Bootstrap from '../extentions/Bootstrap';

export default function() {
    return {
        view: function() {
            return (
                <footer className="row flex-nowrap align-items-center justify-content-between">
                    <div className="d-none d-sm-none d-md-block col-md-6 font-size-small">
                        <div className="ml-2">
                            <span>
                                {_t('footer.text.about')} <a target="_blank" className="text-nowrap" href={window.config.customerServiceLink}>{_t('footer.text.contactus')}</a>
                            </span>
                        </div>
                    </div>
                    <div className={(Bootstrap.isTabletView() ? 'px-2' : '') + ' col-sm-12 col-md-6 d-flex justify-content-end'}>
                        <div className={(Bootstrap.isTabletView() ? 'w-100' : 'pr-3')}>
                            <LanguageSelector />
                        </div>
                    </div>
                </footer>
            );
        },
    };
}
