export default function () {
    return {
        oncreate: function (vnode) {
            $(vnode.dom).tooltip({html: true});
        },
        onbeforeremove: function (vnode) {
            $(vnode.dom).tooltip('destroy');
        },
        view: function (vnode) {
            return (
                <span className="ml-1 d-inline-block icon-tooltip bs-tooltip" data-toggle="tooltip" data-placement="top" data-container="body" data-original-title={ vnode.attrs.message }/>
            );
        },
    };
}
