import ModalDialog from '../../../widgets/ModalDialog';

export default function () {
    let ContainerDialog;
    let message;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            message = item;
            ContainerDialog.show(item || this);
        },
        view: function () {
            if (! ContainerDialog.isVisible()) {
                return '';
            }

            let header = _t('login.cantgetorder.error');
            let body = <span className="font-color-title font-size-large font-bold">{_t(message)}</span>;
            let iconClass = 'icon-error';

            let cancelContent = <span className="d-flex align-items-center">
                <span className="d-inline-block">{_t('order.deleted.tryagain')}</span>
                <span className="d-inline-block icon-retry-w icon-margin-left" />
            </span>;

            return (
                <ContainerDialog iconClass={iconClass} canClose={true} outsideClickClose={true}
                    header={header} body={body} cancelContent={cancelContent} />
            );
        },
    };
}
