import FormDialog from '../complaint/FormDialog';
import OrderStatusButton from '../../../../widgets/OrderStatusButton';
import Bootstrap from '../../../../extentions/Bootstrap';

export default function () {
    let complaintDialog;
    return {
        view: function (vnode) {
            let order = vnode.attrs.order;
            return (
                <div className={Bootstrap.isMobileView() ? 'w-100' : ''}>
                    <OrderStatusButton onClick={() => complaintDialog.show(order)} message={_t('complaint.cta.form')}/>
                    <FormDialog oncreate={function (vnode) {
                        complaintDialog = vnode.state;
                    }} order={order}/>
                </div>
            );
        },
    };
}
