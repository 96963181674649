import Bootstrap from '../../../extentions/Bootstrap';
import Tooltip from '../../../widgets/Tooltip';

export default function() {
    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            let duplicatedOrdersString = '';
            order.getDuplicatedOrders().forEach(function (orderUid, index) {
                const link = window.location.origin + '/enter/' + orderUid;
                duplicatedOrdersString = duplicatedOrdersString + '<a href="' + link + '">' + orderUid + '</a>'
                    + (order.getDuplicatedOrders().length - 1 !== index ? ', ' : '');
            });

            return (
                <div className="row mt-1 mb-1">
                    <div className="col-12 order-list-col">
                        <div className="order-list cart-style">
                            <div className={(Bootstrap.isTabletView() ? 'p-2' : 'p-3')}>
                                <p className="font-color-title font-size-small text-uppercase font-bold m-0">{_t('order.duplicate.title')}</p>
                                <p className="m-0 font-color-title font-bold font-size-big d-flex align-items-center">
                                    <span className="d-inline-block">{_t('order.duplicate.description')}</span>
                                    <Tooltip message={_t('order.duplicate.tooltip').children} />
                                </p>
                                <p className="font-size-small m-0 pl-1">
                                    <span>{_t('order.duplicate.text')}&nbsp;</span>
                                    <span>{m.trust(duplicatedOrdersString)}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    };
}
