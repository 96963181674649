import Bootstrap from '../extentions/Bootstrap';

let SideModalDialog = function () {

    let instance;
    let visible = false;
    const scrollbarWidth = Bootstrap.getScrollBarWidth();

    let setMarginToElementIfOverFlow = function (element) {
        let el = $(element);
        let margin = Bootstrap.isScrollShown(element) ? scrollbarWidth : 0;

        let headerEl = el.find('.header-element');
        let footerEl = el.find('.footer-element');

        let headerElContent = el.find('.header-element-content');
        let footerElContent = el.find('.footer-element-content');

        headerEl.css('margin-right', 0);
        footerEl.css('margin-right', 0);
        headerElContent.css('margin-right', 0);
        footerElContent.css('margin-right', 0);

        if (Bootstrap.isTabletView()) {
            headerElContent.css('margin-right', margin);
            footerElContent.css('margin-right', margin);
        } else {
            headerEl.css('margin-right', margin);
            footerEl.css('margin-right', margin);
        }
    };

    function close() {
        visible = false;
        instance && instance.modal('hide');
        instance = undefined;
    }

    function cancel(onCancel) {
        onCancel && onCancel();
        close();
    }

    return {
        show: function (item) {
            visible = item || true;
        },
        cancel: function (onCancel) {
            cancel(onCancel);
        },
        isVisible: function() {
            return !! visible;
        },
        hide: function () {
            close();
        },
        onremove: function () {
            close();
        },
        onupdate : function (vnode) {
            setMarginToElementIfOverFlow(vnode.dom);
        },
        oncreate: function (vnode) {
            let element = vnode.dom;
            let el = $(element);

            el.on('shown.bs.modal', function () {
                if (scrollbarWidth > 0) {
                    setMarginToElementIfOverFlow(element);
                }
                vnode.attrs.focus && setTimeout(function() {$(vnode.attrs.focus).focus();}, 10);

                element.onkeydown = function (e) {
                    if (27 === e.keyCode) {
                        e.stopImmediatePropagation();
                        vnode.attrs.onCancel();
                        m.redraw();
                    }
                };
            });

            if (scrollbarWidth > 0) {
                $(window).resize(function() {
                    setMarginToElementIfOverFlow(element);
                });
            }

            vnode.attrs.hideScroll();

            if (! instance) {
                if (vnode.attrs.outsideClickClose === true) {
                    element.onclick = function (e) {
                        if (! $('.modal-content', element)[0].contains(e.target)) {
                            cancel();
                            m.redraw();
                            vnode.attrs.onCancel();
                            typeof vnode.attrs.outsideClickCloseCallback === 'function' && vnode.attrs.outsideClickCloseCallback();
                        }
                    };
                }
                instance = el.modal({
                    keyboard: true,
                    backdrop: vnode.attrs.outsideClickClose === true ? true : 'static',
                });
                typeof vnode.attrs.onShow === 'function' && vnode.attrs.onShow();
                el.on('hidden.bs.modal', function(){
                    if (visible) {
                        visible = false;
                        m.redraw();
                    }
                });
            }
        },
        view: function (vnode) {
            return (
                <div className={vnode.attrs.modalClass + ' modal px-0 fade show'} aria-hidden="true" role="dialog" tabindex="-1">
                    <div className="modal-dialog modal-dialog-scrollable modal-lg float-right h-100 p-0 m-0" role="document">
                        <div className="modal-content h-100 border-0">
                            <div className="header-element-blank w-100" />
                            <div className='header-element fixed-top'>
                                {vnode.attrs.header}
                            </div>
                            <div className="modal-body p-0">
                                {vnode.attrs.body}
                            </div>
                            <div className="footer-element-blank w-100" />
                            <div className="footer-element fixed-bottom">
                                {vnode.attrs.footer}
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    };
};

export default SideModalDialog;
