import OrderStatusType from './OrderStatusType';

let OrderStatusTypeTextMap = {};

OrderStatusTypeTextMap[OrderStatusType.LOCKED] = 'orderstatus.text.unlocked';
OrderStatusTypeTextMap[OrderStatusType.UNLOCKED_INVOICED] = 'orderstatus.text.invoiced';
OrderStatusTypeTextMap[OrderStatusType.LOCKED_PURCHASED] = 'orderstatus.text.purchased';
OrderStatusTypeTextMap[OrderStatusType.LOCKED_DELIVERED] = 'orderstatus.text.delivered';
OrderStatusTypeTextMap[OrderStatusType.UNLOCKED] = 'orderstatus.text.unlocked';

export default OrderStatusTypeTextMap;
