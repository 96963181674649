import Tooltip from '../../../../widgets/Tooltip';

export default function () {
    return {
        view: function (vnode) {
            let invoice = vnode.attrs.order.getInvoice();
            return (<div>
                <p className="m-0 font-color-title font-bold font-size-big d-flex align-items-center">
                    <span className="d-inline-block">{invoice.getTextTitle()}</span>
                    <Tooltip message={invoice.getTooltipTitle().children}/>
                </p>
            </div>);
        },
    };
}
