let XhrUtilities = {
    extractResponse: function(xhr) {
        let body;
        try {
            body = JSON.parse(xhr.response);
        } catch (error) {
            body = null;
        }
        return {status: xhr.status, body: body};
    },
    configHttpRequest: function (xhr) {
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    },
    getGeneralErrorMessage: function () {
        return 'general.error.text';
    },
};

export default XhrUtilities;
