import ShopDeliveryModel from './delivery/ShopDelivery';

let OrderShopModel = function (Translator, shopData) {

    let delivery = new ShopDeliveryModel(Translator, shopData);

    this.getCountry = function () {
        return shopData.country;
    };

    this.getName = function () {
        return shopData.name;
    };

    this.getHatSizeDimension = function () {
        return shopData.hatSizeDimension;
    };

    this.getDelivery = function () {
        return delivery;
    };

    this.getType = function () {
        return shopData.type;
    };

};

export default OrderShopModel;