import UuidGenerator from '../extentions/UuidGenerator';
import InvalidFeedbackMessage from './InvalidFeedbackMessage';
import Bootstrap from '../extentions/Bootstrap';

export default function () {
    let uuid;

    return {
        oncreate: function (vnode) {
            if (vnode.attrs.checked) {
                $('input', $(vnode.dom)).attr('checked', 'checked');
            }
        },
        oninit: function () {
            uuid = UuidGenerator.uuidv4();
        },
        view: function (vnode) {
            return (
                <div className="custom-checkbox-container">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input shadow-none" id={uuid} required="required" onchange={(e) => vnode.attrs.onChange(e.target.checked)} />
                        <label className="custom-control-label text-uppercase shadow-none" htmlFor={uuid} />
                        <span className="text-uppercase d-inline-block">{vnode.attrs.label}</span>
                        <InvalidFeedbackMessage message={_t('form.errormessage.empty')}/>
                    </div>
                </div>
            );
        },
    };
}
