import ModalDialog from '../../../widgets/ModalDialog';
import InputLabel from '../../../widgets/InputLabel';
import HiddenSubmitButton from '../../../widgets/HiddenSubmitButton';
import InvalidFeedbackMessage from '../../../widgets/InvalidFeedbackMessage';

export default function () {
    let ContainerDialog;

    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        hide: function () {
            ContainerDialog.hide();
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }

            let onCreate = function onCreate() {
                vnode.attrs.form.addSubmitListener($('form[name="' + vnode.attrs.form.getFormName() + '"]')[0]);
            };

            let form = vnode.attrs.form;
            let header = _t('message_0072');
            let body = <div className="form-container">
                <form name={form.getFormName()} novalidate>
                    <p className="font-color-title mb-3">
                        {_t('message_0073')}
                    </p>

                    <InputLabel label={_t('message_0024')}/>
                    <div className="input-group input-container">
                        <input type="email" className="form-control shadow-none" autoFocus required="required" placeholder={_t('login.forgotnumber.placeholder').children} value={form.getEmail()} oninput={(e) => form.setEmail(e.target.value)}/>
                        <InvalidFeedbackMessage message={_t('login.forgotnumber.emailvalidation')}/>
                    </div>

                    <HiddenSubmitButton/>
                </form>
            </div>;

            let cancelContent = <span className="d-flex align-items-center">
                <span className="d-inline-block icon-margin-right">{_t('login.modal.requestordernumber')}</span>
                <span className="d-inline-block icon-arrow-login" />
            </span>;

            return (
                <ContainerDialog canClose={true} canCloseAction={() => ContainerDialog.hide()} onCancel={vnode.attrs.onCancel} outsideClickClose={true}
                    header={header} body={body} cancelContent={cancelContent} cancelAction={vnode.attrs.onSubmit} onCreate={onCreate} paddingDesktopCustom="px-4 pt-4 pb-2"/>
            );
        },
    };
}
