import Bootstrap from '../../../../../extentions/Bootstrap';

export default function () {
    return {
        view: function (vnode) {
            let order = vnode.attrs.order;
            return (
                <div className="h-100 d-flex align-items-center justify-content-center footer-element-content">
                    <div className={(Bootstrap.isTabletView() ? 'px-2' : 'px-4') + ' w-100 text-uppercase'}>
                        <div className="d-inline-block button-container">
                            <button className="d-flex btn btn-secondary-abc w-100 h-100 link-color align-items-center justify-content-center"
                                onclick={() => vnode.attrs.cancelDialog.show(vnode.attrs.containerDialog)}>
                                <span className="text-uppercase">
                                    {_t('message_0052')}
                                </span>
                            </button>
                        </div>

                        <div className="d-inline-block button-container float-right">
                            <button className="d-flex btn btn-info-abc w-100 h-100 align-items-center justify-content-center"
                                onclick={() => {
                                    vnode.attrs.submitDialog(order);
                                }}>
                                <span className="text-uppercase">
                                    {_t('complaint.cta.submit')}
                                </span>
                            </button>
                        </div>
                    </div>

                </div>
            );
        },
    };
}
