import Bootstrap from '../../../../extentions/Bootstrap';

export default function() {
    return {
        view: function(vnode) {
            let row = vnode.attrs.row;
            return (
                <div>
                    {
                        row.hasTextPrint() && row.getTextPrint() && !Bootstrap.isMobileView()
                            ? <span>
                                <span className="col-12 d-block font-color-title font-bold text-uppercase">{_t('message_0045')}</span>
                                <span className="col-12 d-block font-size-small">{m.trust(row.getTextPrint())}</span>
                            </span>
                            : ''
                    }
                </div>
            );
        },
    };
}
