import CustomPhoto from './photo/CustomPhoto';

export default function() {
    return {
        view: function(vnode) {
            let row = vnode.attrs.row;
            return (
                <tr>
                    <td className="w-65">
                        <div>
                            <span className="col-12 d-block font-bold font-color-title font-size-big">{row.getProductName()}</span>
                            {row.hasProductVariants()
                                ? <span className="col-12 font-color-title d-block">{row.getProductVariants()}</span>
                                : ''
                            }
                        </div>
                    </td>
                    <td className="w-25">
                        <CustomPhoto row={row} order={vnode.attrs.order} />
                        {row.hasTextPrint() && row.getTextPrint()
                            ? <div className="pl-1"><span className="col-12 d-block font-color-title font-bold font-size-big">{_t('message_0045')}</span>
                                <span className="col-12 d-block font-size-small">{m.trust(row.getTextPrint())}</span>
                            </div>
                            : ''
                        }
                    </td>
                    <td className="text-right w-10">
                        <span className="col-12 d-block font-color-title font-bold price-column">{row.getPrice()}</span>
                        {row.hasSavings()
                            ? <span className="col-12 d-block text-secondary font-size-small"><del>{row.getListPrice()}</del></span>
                            : ''
                        }
                    </td>
                </tr>
            );
        },
    };
}
