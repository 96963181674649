import OrderStatusType from './OrderStatusType';
import OrderStatusTypeComponentMap from './OrderStatusTypeComponentMap';
import OrderStatusTypeButtonComponentMap from './OrderStatusTypeButtonComponentMap';
import OrderStatusTypeTooltipMap from './OrderStatusTypeTooltipMap';
import OrderStatusTypeTextMap from './OrderStatusTypeTextMap';

let OrderStatusModel = function (Translator, orderData) {

    let type = OrderStatusType.UNLOCKED;
    let lockDate = orderData.myOrderLockDate ? new Date(orderData.myOrderLockDate.replace(/-/g, "/")).valueOf() : null;

    orderData = orderData || null;

    this.getComponent = function () {
        this.updateType();
        return OrderStatusTypeComponentMap[type];
    };

    this.getComponentButton = function () {
        this.updateType();
        return OrderStatusTypeButtonComponentMap[type];
    };

    this.getTooltipTitle = function (substitutions) {
        this.updateType();
        return Translator.translate(OrderStatusTypeTooltipMap[type], substitutions);
    };

    this.getTextTitle = function (substitutions) {
        this.updateType();
        return Translator.translate(OrderStatusTypeTextMap[type], substitutions);
    };

    this.getLockDate = function () {
        return lockDate;
    };

    this.isLocked = function () {
        return orderData.isLocked || (lockDate ? lockDate < (new Date()).getTime() : !! lockDate);
    };

    this.updateType = function () {
        type = OrderStatusType.UNLOCKED;

        if (this.isLocked()) {
            type = OrderStatusType.LOCKED;
        }
        if (orderData.schoolWebShop.invoiced) {
            type = OrderStatusType.UNLOCKED_INVOICED;
        }
        if (orderData.schoolWebShop.ordered) {
            type = OrderStatusType.LOCKED_PURCHASED;
        }
        if (orderData.schoolWebShop.delivered) {
            type = OrderStatusType.LOCKED_DELIVERED;
        }
    };

    this.updateType();
};

export default OrderStatusModel;
