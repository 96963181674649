let Cookies = function() {
    'use strict';

    /**
     * Returns the cookie value from the current cookies set.
     *
     * @param {String} name
     * @return {String}
     */
    this.getCookie = function(name) {
        var matches = document.cookie.match(new RegExp(
            '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
        ));
        return matches ? decodeURIComponent(matches[1]) : '';
    };

    /**
     * Sets the cookie value and options.
     * Options can contain {expires, path, domain, secure}
     *
     * @param {String} name
     * @param {String} value
     * @param {Object} options
     */
    this.setCookie = function(name, value, options) {
        options = options || {};

        options.Path = '/';

        var expires = options.expires;

        if (typeof expires == 'number' && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }

        value = encodeURIComponent(value);

        var updatedCookie = name + '=' + value;

        for (var propName in options) {
            updatedCookie += '; ' + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += '=' + propValue;
            }
        }

        document.cookie = updatedCookie;
    };

    /**
     * Deletes cookie from the current cookies set.
     *
     * @param {String} name
     */
    this.deleteCookie = function(name) {
        //self.setCookie(name, "", { expires: -1000 });
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };
};

export default Cookies;
