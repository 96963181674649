import ModalDialog from '../../../../widgets/ModalDialog';

export default function () {
    let ContainerDialog;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }
            let header = _t('deleteorder.success.header');
            let body = <span className="font-color-title font-size-large font-bold">{_t('deleteorder.success.text')}</span>;
            let cancelContent = <span>{_t('message_0052')}</span>;
            let iconClass = 'icon-success';

            return (
                <ContainerDialog hideScroll={vnode.attrs.hideScroll} iconClass={iconClass} outsideClickClose={true} canClose={true} onCancel={() => vnode.attrs.onCancel()}
                    header={header} body={body} cancelContent={cancelContent}/>
            );
        },
    };
}
