let DimensionVariantModel = function (Translator, value, variantData) {

    this.getValue = function (raw) {
        return raw
            ? value
            : (typeof variantData !== 'undefined'
                ? Translator.getTranslation(variantData.translations)
                : 'N/A'
            )
        ;
    };

};

export default DimensionVariantModel;
