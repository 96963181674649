import Bootstrap from '../../../../extentions/Bootstrap';

export default function() {
    return {
        view: function(vnode) {
            let row = vnode.attrs.row;
            return (
                <span className="col-12">
                    {
                        row.hasTextPrint() && row.getTextPrint() && Bootstrap.isMobileView()
                            ? <span className="d-flex align-items-center">
                                <span className="d-inline-block icon-lasercut" />
                                <span className="d-inline-block icon-margin-left font-size-small">{m.trust(row.getTextPrint())}</span>
                            </span>
                            : ''
                    }
                </span>
            );
        },
    };
}
