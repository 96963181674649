let OrderDelete = function (setupData) {
    let self = this;

    let reason = 0;

    this.getType = function () {
        return 'order-delete-form';
    };

    this.getReasons = function () {
        return setupData.reasons;
    };

    this.isApplicable = function (order) {
        return order.isNoHat() === false;
    };

    this.getReason = function () {
        return reason;
    };

    this.setReason = function (value) {
        reason = parseInt(value);
    };

    this.getPath = function () {
        return '/order-delete';
    };

    this.getFormData = function() {
        let data = new FormData();
        data.append('form', JSON.stringify(this.toJSON().form));
        return data;
    };

    this.toJSON = function () {
        return {
            form: {
                reason: reason
            },
        };
    };
};

export default OrderDelete;
