import Bootstrap from '../../../../../extentions/Bootstrap';

export default function () {
    return {
        view: function (vnode) {
            return (
                <div className={(Bootstrap.isTabletView() ? 'px-2' : 'px-4') + ' h-100 d-flex justify-content-between align-items-center header-element-content'}>
                    <h3 className="m-0">{_t('complaint.modal.header')}</h3>

                    <div className="modal-close p-0">
                        <a className="d-flex justify-content-center align-items-center border-0 rounded" aria-label="Close" data-dismiss="modal" onclick={() => {
                            vnode.attrs.cancelDialog.show(vnode.attrs.containerDialog);
                        }}>
                            <span className="d-block icon-close"/>
                        </a>
                    </div>
                </div>
            );
        },
    };
}
