import Bootstrap from '../../../extentions/Bootstrap';
import Country from '../../../extentions/Country';

export default function() {
    return {
        oncreate: function(vnode) {
            let order = vnode.attrs.order;

            let iFrameHat = vnode.dom.querySelector('.iframe-hat');
            let width = Country.isSe() ? vnode.dom.offsetWidth : (Bootstrap.isMobileView() ? 300 : 500);
            let height = Country.isSe() ? vnode.dom.offsetHeight : (Bootstrap.isMobileView() ? 200 : 350);

            iFrameHat.querySelector('iframe').onload = function () {
                const hiddenContainerClass = 'hidden-container';
                vnode.dom.querySelector('.iframe-hat').classList.remove(hiddenContainerClass);
                vnode.dom.querySelector('.iframe-hat-spinner').classList.add(hiddenContainerClass);
            };
            iFrameHat.querySelector('iframe').src = order.getHatLink() + '&width=' + width + '&height=' + height;

        },
        view: function(vnode) {
            let order = vnode.attrs.order;

            return (
                <div className="item-element cart-style h-100">
                    <div className="iframe-hat-spinner">
                        <div className="d-flex h-100 w-100 justify-content-center align-items-center hidden-element">
                            {m.trust(Bootstrap.getSpinner())}
                        </div>
                    </div>

                    <div className="iframe-hat hidden-container">
                        <div className="h-100 w-100 hidden-element">
                            <iframe className="h-100 w-100 border-0" scrolling="no" key={order.getKey()} />
                        </div>
                    </div>
                </div>
            );
        },
    };
}
