let DialogBody = function () {
    return {
        view: function (vnode) {
            let model = vnode.attrs.model;

            return (
                <div>
                    {
                        model.getUrl() ?
                            <img className="h-100 w-100" src={model.getUrl()} />
                            :
                            <div className="photo-not-uploaded w-100 d-flex justify-content-center align-items-center" onclick={() => {model.setPreviewMode(false);}}>
                                <span className="font-color-danger font-bold font-size-large">{_t('photo.text.notuploaded')}</span>
                            </div>
                    }
                </div>
            );
        },
    };
};

export default DialogBody;
