export default function() {
    return {
        view: function(vnode) {
            let row = vnode.attrs.row;
            return (
                <tr>
                    <td className="border-0 py-0" colSpan="3">
                        <div className="w-100 pl-2">
                            <table className="table variants-table mb-0">
                                <tr className="first-variant">
                                    <td className="w-65">
                                        <div>
                                            <span className="col-12 d-block font-color-title font-bold text-uppercase">{row.getProductVariantNameForVar2()}</span>
                                            <span className="col-12 d-block font-size-small">{row.getProductVariantValueForVar2()}</span>
                                        </div>
                                    </td>
                                    <td className="w-25">
                                    </td>
                                    <td className="text-right w-10">
                                        <span className="col-12 d-block font-color-title font-bold price-column">{row.getVariantPriceForDimension('VAR_2')}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
            );
        },
    };
}
