import ComplaintCategoryTypeTitleMap from './ComplaintCategoryTypeTitleMap';
import ComplaintCategoryTypeComponentMap from './ComplaintCategoryTypeComponentMap';

let ComplaintReturnProductModel = function (type, setupData) {

    let product;
    let description;
    let returnFee = false;
    let reason = 0;

    this.getType = function () {
        return type;
    };

    this.getTitle = function () {
        return ComplaintCategoryTypeTitleMap[type];
    };

    this.getProducts = function (order) {
        let products = {};
        _.each(order.getRows(), function (row) {
            if (row.getProductType() !== 'forhats'
                && row.isVisible()
                && setupData.excludeProducts.indexOf(row.getProduct().getArticleNumber()) === -1
            ) {
                products[row.getId()] = row.hasProductVariants() ? row.getProductName() + ' [' + row.getProductVariants() + ']' : row.getProductName();
            }
        });
        return products;
    };

    this.getReasons = function () {
        return setupData.reasons;
    };

    this.getComponent = function (order) {
        return m(ComplaintCategoryTypeComponentMap[type], {order: order, categoryModel: this});
    };

    this.isApplicable = function (order) {
        return Object.keys(this.getProducts(order)).length > 0;
    };

    this.getProduct = function () {
        return product;
    };

    this.setProduct = function (value) {
        product = value;
    };

    this.getDescription = function () {
        return description;
    };

    this.getReason = function () {
        return reason;
    };

    this.setReason = function (value) {
        reason = parseInt(value);
    };

    this.setDescription = function (value) {
        description = value;
    };

    this.getReturnFee = function () {
        return !! returnFee;
    };

    this.setReturnFee = function (value) {
        returnFee = value;
    };

    this.isReady = function () {
        return returnFee && !! description;
    };

    this.getPath = function () {
        return '/complaint/return';
    };

    this.getFormData = function() {
        let data = new FormData();
        data.append('form', JSON.stringify(this.toJSON().form));
        return data;
    };

    this.toJSON = function () {
        return {
            form: {
                product: product,
                description: description,
                reason: reason,
            },
        };
    };
};

export default ComplaintReturnProductModel;
