let ShopDeliveryType = {};

ShopDeliveryType.HOME = 'home';
ShopDeliveryType.HOME_CONFIRMED = 'home_confirmed';
ShopDeliveryType.HOME_CONCEALED = 'home_concealed';
ShopDeliveryType.SCHOOL = 'school';
ShopDeliveryType.SCHOOL_CONFIRMED = 'school_confirmed';
ShopDeliveryType.SCHOOL_CONCEALED = 'school_concealed';
ShopDeliveryType.FITTING = 'fitting';

export default ShopDeliveryType;