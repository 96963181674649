import HatSizeMeasureGuide from './HatSizeMeasureGuide';
import OptionSelector from '../../../../../widgets/OptionSelector';
import Textarea from '../../../../../widgets/Textarea';
import Country from '../../../../../extentions/Country';
import InputLabel from '../../../../../widgets/InputLabel';
import HiddenSubmitButton from '../../../../../widgets/HiddenSubmitButton';

export default function () {
    return {
        oncreate: function (vnode) {
            let formModel = vnode.attrs.order.getComplaintFormModel();
            formModel.addSubmitListener($(vnode.dom).find('form[name="' + vnode.attrs.categoryModel.getType() + '"]')[0]);
        },
        view: function (vnode) {
            let order = vnode.attrs.order;
            let model = vnode.attrs.categoryModel;
            let hatSize = order.getHatSize();

            return (
                <div className="form-container">
                    <form name={model.getType()} novalidate>
                        <div>
                            <InputLabel label={_t('complaint.label.sizereasons')}/>
                            <OptionSelector options={model.getReasons()} selected={model.getReason()} onChange={model.setReason} focusClass={vnode.attrs.order.getComplaintFormModel().getComplaintClass()} />
                        </div>
                        {
                            model.hasSizeReasons()
                                ? <div className="pt-2">
                                    <InputLabel label={_t('complaint.label.sizereasondifference')}/>
                                    <OptionSelector options={model.getSizeReasons()} selected={model.getSizeReason()} onChange={model.setSizeReason} focusClass={vnode.attrs.order.getComplaintFormModel().getComplaintClass()} />
                                </div>
                                : null
                        }
                        {
                            model.hasDescription()
                                ? <div className="pt-2">
                                    <InputLabel label={_t('complaint.label.sizedescription')}/>
                                    <Textarea onChange={model.setDescription} required="true" value={model.getDescription()} placeholder={_t('complaint.wrongsize.textarea.placeholder').children} />
                                </div>
                                : <div className="pt-2" />
                        }
                        {
                            model.hasQuickerAssistanceLink()
                                ? <div className="pt-2">
                                    {_t('complaint.reason.sizebigadjustmenttext')}
                                </div>
                                : null
                        }
                        <div>
                            { Country.isSe() ? <HatSizeMeasureGuide modal={vnode.attrs.auxModal}/> : '' }
                        </div>
                        <HiddenSubmitButton />
                    </form>
                </div>
            );
        },
    };
}
