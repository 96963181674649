import DialogDelete from '../modal/DialogDelete';
import OrderStatusButton from '../../../../widgets/OrderStatusButton';
import FormDialog from '../delete/FormDialog';

export default function () {
    let deleteDialog;
    return {
        view: function (vnode) {
            let order = vnode.attrs.order;
            return (
                <div className="w-100">
                    <OrderStatusButton disabled={order.isLocked()} title={order.isLocked() ? _t('button.orderdelete.title').children : null} onClick={() => deleteDialog.show(order)} message={_t('message_0036')}/>
                    <FormDialog oncreate={function (vnode) {
                        deleteDialog = vnode.state;
                    }} order={order}/>
                </div>
            );
        },
    };
}
