import ComplaintCategoryTypeTitleMap from './ComplaintCategoryTypeTitleMap';
import ComplaintCategoryTypeComponentMap from './ComplaintCategoryTypeComponentMap';
import Country from '../../extentions/Country';

let ComplaintWrongSizeModel = function (type, setupData) {
    let self = this;

    let reason = 0;
    let sizeReason = 0;
    let description;

    this.getType = function () {
        return type;
    };

    this.getTitle = function () {
        return ComplaintCategoryTypeTitleMap[type];
    };

    this.getReasons = function () {
        return setupData.reasons;
    };

    this.getSizeReasons = function () {
        return setupData.sizeReasons;
    };

    this.getComponent = function (order, auxModal) {
        return m(ComplaintCategoryTypeComponentMap[type], {order: order, categoryModel: this, auxModal: auxModal});
    };

    this.isApplicable = function (order) {
        return order.isNoHat() === false;
    };

    this.getReason = function () {
        return reason;
    };

    this.setReason = function (value) {
        reason = parseInt(value);
    };

    this.getSizeReason = function () {
        return sizeReason;
    };

    this.setSizeReason = function (value) {
        sizeReason = parseInt(value);
    };

    this.hasSizeReasons = function () {
        let reasons = self.getReasons();
        return parseInt(_.invert(reasons)['complaint.reason.sizebig']) === self.getReason()
            || parseInt(_.invert(reasons)['complaint.reason.sizesmall']) === self.getReason();
    };

    this.getDescription = function () {
        return description;
    };

    this.hasDescription = function () {
        let sizeReasons = self.getSizeReasons();
        return self.hasSizeReasons()
            ? ! (parseInt(_.invert(sizeReasons)['complaint.sizereasons.hugedifference']) === self.getSizeReason())
            : true;
    };

    this.hasQuickerAssistanceLink = function () {
        let reasons = self.getReasons();
        let sizeReasons = self.getSizeReasons();
        return Country.isSe() && parseInt(_.invert(reasons)['complaint.reason.sizebig']) === self.getReason() &&
            (parseInt(_.invert(sizeReasons)['complaint.sizereasons.smalldifference']) === self.getSizeReason() || parseInt(_.invert(sizeReasons)['complaint.sizereasons.significantdifference']) === self.getSizeReason());
    };

    this.setDescription = function (value) {
        description = value;
    };

    this.getPath = function () {
        return '/complaint/size';
    };

    this.isReady = function () {
        return !! description;
    };

    this.getFormData = function() {
        let data = new FormData();
        data.append('form', JSON.stringify(this.toJSON().form));
        return data;
    };

    this.toJSON = function () {
        return {
            form: {
                reason: reason,
                sizeReason: self.hasSizeReasons() ? sizeReason : null,
                description: self.hasDescription() ? description : null,
            },
        };
    };
};

export default ComplaintWrongSizeModel;
