import ModalDialog from '../../../widgets/ModalDialog';
import SupportElement from './SupportElement';
import XhrUtilities from '../../../extentions/XhrUtilities';

export default function () {
    let ContainerDialog;
    let responseCode;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (code) {
            responseCode = code;
            ContainerDialog.show(this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }
            let header = _t('message_0072');
            let body =  200 === responseCode ?
                <span className="font-color-title font-size-large font-bold">{_t('message_0074')}</span> :
                (503 === responseCode ? <SupportElement text={_t('login.forgotnumber.emailnotfound')}/> : <span className="font-color-title font-size-large font-bold">{_t(XhrUtilities.getGeneralErrorMessage())}</span>);

            let cancelContent = 200 === responseCode ?
                <span className="d-flex align-items-center">
                    <span className="d-inline-block icon-margin-right">{_t('login.cta.login')}</span>
                    <span className="d-inline-block icon-arrow-login" />
                </span> :
                <span className="d-flex align-items-center">
                    <span className="d-inline-block">{_t('order.deleted.tryagain')}</span>
                    <span className="d-inline-block icon-retry-w icon-margin-left" />
                </span>;
            let iconClass = 200 === responseCode ? 'icon-success' : 'icon-error';

            return (
                <ContainerDialog iconClass={iconClass} canClose={true} onCancel={vnode.attrs.onCancel} outsideClickClose={true}
                    header={header} body={body} cancelContent={cancelContent} />
            );
        },
    };
}
