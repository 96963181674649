import ModalDialog from '../../../../widgets/ModalDialog';

export default function () {
    let ContainerDialog;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }
            let header = _t('message_0035');
            let body = <span><p className="font-color-title font-size-large font-bold">{_t('message_0050')}</p><p className="font-color-title font-size-large font-bold">{_t('message_0051')}</p></span>;
            let cancelContent = <span>{_t('message_0053')}</span>;
            let acceptContent = <span>{_t('message_0052')}</span>;
            let acceptAction = function () {
                ContainerDialog.hide();
            };
            return (
                <ContainerDialog canClose={true} outsideClickClose={true} onCancel={vnode.attrs.onCancel}
                    header={header} body={body} cancelContent={cancelContent} acceptAction={acceptAction} canCloseAction={acceptAction} cancelAction={() => window.location.replace(vnode.attrs.order.getChangeLink())} acceptContent={acceptContent}/>
            );
        },
    };
}
