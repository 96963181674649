import Tooltip from '../../../../widgets/Tooltip';

export default function () {
    return {
        view: function (vnode) {
            let invoice = vnode.attrs.order.getInvoice();
            return (<div className="d-flex d-sm-block">
                {/* eslint-disable-next-line mithril/jsx-no-target-blank */}
                <a href={invoice.getUrl()} target="_blank" className="m-0 font-bold font-size-big">
                    <u>{invoice.getTextTitle()}</u>
                </a>
                <Tooltip message={invoice.getTooltipTitle().children}/>
            </div>);
        },
    };
}
