import FormContainer from './FormContainer';

export default function() {
    return {
        view: function(vnode) {
            return (
                <div className="rounded form form-desktop mx-auto my-auto">
                    <div className="py-5 px-6">
                        <FormContainer order={vnode.attrs.order} form={vnode.attrs.form} showOrderNotFoundDialog={vnode.attrs.showOrderNotFoundDialog} lostLoginRequestDialog={vnode.attrs.lostLoginRequestDialog}/>
                    </div>
                </div>
            );
        },
    };
}
