import DateHelper from '../extentions/DateHelper';

import VanillajsDatepicker from 'vanillajs-datepicker/Datepicker';

import sv from 'vanillajs-datepicker/locales/sv';
import da from 'vanillajs-datepicker/locales/da'

Object.assign(VanillajsDatepicker.locales, sv, da);

let Datepicker = function () {
    return {
        oncreate: function (vnode) {
            let minDate = vnode.attrs.startDate;
            let date = new Date(minDate);
            date.setDate(date.getDate() + 90);
            const datepicker = new VanillajsDatepicker(vnode.dom, {
                weekStart: 1,
                format: 'yyyy-mm-dd',
                minDate: minDate,
                maxDate: DateHelper.getDateInSeFormat(date),
                language: m.trans.getLanguage(),
            });
            datepicker.element.addEventListener('changeDate', (e) => {
                vnode.attrs.onPickDate(DateHelper.getDateInSeFormat(new Date(datepicker.getDate())));
                m.redraw();
            });
        },
        onremove: function (vnode) {
            vnode.dom.datepicker.destroy();
        },
        view: function () {
            return m('div');
        },
    };
};

export default Datepicker;