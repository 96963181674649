import OrderStatusType from './OrderStatusType';
import ComplaintButton from '../../../component/page/order/button/OrderComplaintButton';

let OrderStatusTypeButtonComponentMap = {};

OrderStatusTypeButtonComponentMap[OrderStatusType.LOCKED] = null;
OrderStatusTypeButtonComponentMap[OrderStatusType.UNLOCKED_INVOICED] = null;
OrderStatusTypeButtonComponentMap[OrderStatusType.LOCKED_PURCHASED] = null;
OrderStatusTypeButtonComponentMap[OrderStatusType.LOCKED_DELIVERED] = ComplaintButton;
OrderStatusTypeButtonComponentMap[OrderStatusType.UNLOCKED] = null;

export default OrderStatusTypeButtonComponentMap;
