import Bootstrap from '../extentions/Bootstrap';

export default function () {
    return {
        oncreate: function (vnode) {
            if (vnode.attrs.title) {
                $(vnode.dom).tooltip({html: true, trigger: (Bootstrap.isTabletView() ? 'focus ' : '') + 'hover'});
            }
        },
        onbeforeremove: function (vnode) {
            if (vnode.attrs.title) {
                $(vnode.dom).tooltip('destroy');
            }
        },
        view: function (vnode) {
            let onclick = function (e) {
                if (! vnode.attrs.disabled) {
                    vnode.attrs.onClick();
                }
            };
            return (
                <div className={'button-container'  + (Bootstrap.isMobileView() ? ' w-100 my-2' : '')}
                     data-toggle="tooltip"
                     data-placement="top"
                     data-container="body"
                     data-original-title={ vnode.attrs.title }>
                    <button type="button"
                        className={'btn btn-secondary-abc btn-block text-uppercase h-100 d-flex align-items-center justify-content-center text-nowrap font-size-small font-color-middle-grey px-2' + (vnode.attrs.disabled ? ' disabled' : '')}
                        onclick={(e) => onclick(e)}
                    >
                        <span className="d-inline-block">{vnode.attrs.message}</span>
                        {vnode.attrs.iconClass ? <span className={'d-inline-block icon-margin-left ' + vnode.attrs.iconClass}/> : ''}
                    </button>
                </div>
            );
        },
    };
}
