import ModalDialog from '../../../../widgets/ModalDialog';
import Form from './postpone/Form';
import DialogSuccess from './postpone/DialogSuccess';
import DialogError from './postpone/DialogError';
import Bootstrap from '../../../../extentions/Bootstrap';

export default function () {
    let ContainerDialog;
    let SuccessDialog;
    let ErrorDialog;
    let respiteDate;

    let submit = function (order) {
        ContainerDialog.hide();
        Bootstrap.blockUI();
        order.postponeInvoice(
            respiteDate,
            function (callback) {
                Bootstrap.unblockUI();
                SuccessDialog.show(callback);
            },
            function () {
                Bootstrap.unblockUI();
                ErrorDialog.show();
            },
        );
    };

    let setDate = function (value) {
        respiteDate = value;
    };

    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return (
                    <div>
                        <DialogError oncreate={function (vnode) {ErrorDialog = vnode.state;}}/>
                        <DialogSuccess oncreate={function (vnode) {SuccessDialog = vnode.state;}}/>
                    </div>
                );
            }
            let order = vnode.attrs.order;
            let header = _t('order.header.postpone');
            let body = <Form order={order} onPickDate={setDate} />;

            let cancelContent = <span>{_t('message_0053')}</span>;
            let cancelAction = function () {
                if (respiteDate && order.getInvoice().getDueDate() !== respiteDate) {
                    $(vnode.dom).find('.incorrect-date').addClass('d-none');
                    submit(order);
                } else {
                    $(vnode.dom).find('.incorrect-date').removeClass('d-none');
                }
            };
            let acceptContent = <span>{_t('message_0052')}</span>;
            let acceptAction = function () {
                ContainerDialog.hide();
            };
            return (
                <div>
                    <DialogError oncreate={function (vnode) {ErrorDialog = vnode.state;}}/>
                    <DialogSuccess oncreate={function (vnode) {SuccessDialog = vnode.state;}}/>
                    <ContainerDialog canClose={true} canCloseAction={acceptAction} outsideClickClose={true} header={header} body={body} cancelContent={cancelContent} cancelAction={cancelAction} acceptAction={acceptAction} acceptContent={acceptContent}/>
                </div>
            );
        },
    };
}
