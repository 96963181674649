import ModalDialog from '../../../../widgets/ModalDialog';

export default function () {
    let ContainerDialog;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(this);
            item.hide();
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }
            let header = _t('complaint.cancel.header');
            let body = <span className="font-color-title font-size-large font-bold">{_t('complaint.cancel.text')}</span>;

            let cancelContent = <span>{_t('complaint.cta.confirm')}</span>;
            let acceptContent = <span>{_t('complaint.cta.cancel')}</span>;

            let iconClass = 'icon-info';

            let cancelAction = function () {
                ContainerDialog.setClosed(false);
                ContainerDialog.hide();
                vnode.attrs.showScroll && vnode.attrs.showScroll();
                vnode.attrs.onConfirm && vnode.attrs.onConfirm();
            };

            let acceptAction = function () {
                ContainerDialog.setClosed(true);
                ContainerDialog.hide();
                vnode.attrs.onCancel && vnode.attrs.onCancel();
            };

            return (
                <ContainerDialog canClose={true} outsideClickClose={true} hideScroll={vnode.attrs.hideScroll} nested={true} onCancel={vnode.attrs.showScroll} iconClass={iconClass} cancelAction={cancelAction} acceptAction={acceptAction} cancelContent={cancelContent} acceptContent={acceptContent} canCloseAction={acceptAction} header={header} body={body}/>
            );
        },
    };
}
