import ModalDialog from '../../../../../widgets/ModalDialog';
import Body from './DialogBody';
import Bootstrap from '../../../../../extentions/Bootstrap';

export default function () {
    let ContainerDialog;

    let clearState = function (model) {
        model.setLocalFile('');
        model.setError('');
        model.setPreviewMode(true);
        m.redraw();
    };

    function cancel(model) {
        ContainerDialog.hide();
        clearState(model);
    }

    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }

            let submit = function () {
                Bootstrap.blockUI();
                model.submit().then(function () {
                    if (model.isUploaded()) {
                        clearState(model);
                        model.setLocalFile('');
                    } else {
                        cancel(model);
                        vnode.attrs.showImageErrorDialog();
                    }
                    Bootstrap.unblockUI();
                });
            };

            let model = vnode.attrs.model;
            let header = model.canSubmit() ? _t('photo.header.preview') : (model.isPreviewMode() ? (model.getUrl() ? _t('photo.header.preview') : _t('photo.header.details')) : _t('photo.header.newphoto'));

            let body = <Body model={model} key={model.getSelectText()}/>;

            let closeAction = function () {cancel(model);};

            let acceptAction = vnode.attrs.order.isLocked() ?
                null :
                model.isPreviewMode() ? (model.getUrl() ? function () {model.setPreviewMode(false);} : closeAction) : closeAction;
            let acceptContent = vnode.attrs.order.isLocked() ?
                null :
                model.isPreviewMode() ?  (model.getUrl() ? <span>{ _t('photo.cta.uploadnewphoto') }</span> : <span>{_t('photo.cta.cancel')}</span>) : <span>{_t('photo.cta.cancel')}</span>;

            let cancelAction = vnode.attrs.order.isLocked() ?
                closeAction :
                (
                    model.isPreviewMode() ?
                        (model.getUrl() ? closeAction : function () {model.setPreviewMode(false);}) :
                        (model.canSubmit() ? function () {submit();} : null)
                );
            let cancelContent = vnode.attrs.order.isLocked() ?
                <span>{_t('photo.cta.continue')}</span> :
                (
                    model.isPreviewMode() ? (model.getUrl() ?  <span>{_t('photo.cta.continue')}</span> : <span>{ _t('photo.cta.uploadphoto') }</span>) :
                        (model.canSubmit() ? <span>{ _t('photo.cta.save') }</span> : null)
                );

            return (
                <ContainerDialog canClose={true} isCentralAligned={() => {return model.isPreviewMode() ? !model.getUrl() : !model.canSubmit();}} outsideClickClose={() => {return ! model.isPreviewMode();}} canCloseAction={closeAction} header={header} body={body} acceptAction={acceptAction} acceptContent={acceptContent} cancelAction={cancelAction} cancelContent={cancelContent}/>
            );
        },
    };
}
