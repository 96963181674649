import ModalDialog from '../../../../widgets/ModalDialog';

export default function () {
    let ContainerDialog;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }
            let header = _t('message_0036');
            // eslint-disable-next-line mithril/jsx-no-target-blank
            let body = <span><p className="font-color-title font-size-large font-bold">{ _t('message_0055') }</p> <a className="font-size-large font-bold" href={window.config.customerServiceLink} target="_blank">{window.config.customerServiceLink}</a></span>;

            let cancelContent = <span>{_t('message_0052')}</span>;
            return (
                <ContainerDialog canClose={true} outsideClickClose={true} onCancel={vnode.attrs.onCancel}
                    header={header} body={body} cancelContent={cancelContent} cancelAction={() => ContainerDialog.hide()}/>
            );
        },
    };
}
