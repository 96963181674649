export default function () {
    return {
        view: function () {
            return (
                <div className="hidden-container">
                    <button className="p-0 m-0 hidden-element" type="submit"/>
                </div>
            );
        },
    };
}
