import OrderItemLabel from './OrderItemLabel';
import Bootstrap from '../../../extentions/Bootstrap';

export default function() {
    return {
        view: function(vnode) {
            let customer = vnode.attrs.customer;
            return (
                <div className="row ml-0 mt-1 item-status cart-style">
                    <div className="col-12 px-2">
                        <div className={'d-flex h-100 w-100 ' + (Bootstrap.isMobileView() ? ' py-2' : '')}>
                            <div className={'icon icon-address ' + (Bootstrap.isMobileView() ? '' : 'mt-2')} />
                            <div className="flex-fill align-self-center pl-1 line-height-middle">
                                <OrderItemLabel label={_t('address.label.info')}/>
                                <p className="font-size-big font-color-title font-bold">{customer.getName()}</p>
                                <p className="font-size-small m-0">{customer.getAddress()}</p>
                                <p className="font-size-small m-0">{customer.getEmail()}</p>
                                <p className="font-size-small m-0">{customer.getPhone()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    };
}
