import Datepicker from '../../../../../widgets/Datepicker';

export default function() {
    return {
        view: function(vnode) {
            let invoice = vnode.attrs.order.getInvoice();
            return (
                <div>
                    <div>
                        <p className="font-color-title font-size-large font-bold">{ _t('order.text.postpone') }</p>
                    </div>

                    <div>
                        <Datepicker startDate={invoice.getDueDate()} onPickDate={vnode.attrs.onPickDate} />
                    </div>

                    <div className="d-none incorrect-date text-danger text-uppercase text-right font-size-smallest">
                        <p>{ _t('order.postpone.error') }</p>
                    </div>
                </div>
            );
        },
    };
}

// order.invoice.respiteDate ? order.invoice.respiteDate : order.invoice.dueDate
