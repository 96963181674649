import ComplaintCategoryTypeMap from '../complaint/ComplaintCategoryTypeMap';
import XhrUtilities from '../../extentions/XhrUtilities';

let ComplaintFormModel = function (compalaintsData) {

    let categories = [];
    let activeCategory;
    let complaintClass= 'modal-complaint';
    let submittedFormValid;

    this.getCategories = function () {
        return categories;
    };

    this.getComplaintClass = function () {
        return complaintClass;
    };

    this.isSubmittedFormValid = function () {
        return submittedFormValid;
    };

    this.isActive = function (category) {
        return activeCategory && activeCategory === category;
    };

    this.getActiveCategory = function (order) {
        return activeCategory ? activeCategory : getFirstApplicableCategory(order);
    };

    this.setActiveCategory = function (category) {
        activeCategory = category;
    };

    this.submit = function (order) {
        let category = this.getActiveCategory(order);
        return m.request({
            method: 'POST',
            url: category.getPath() + '/:uid',
            params: {uid: order.getUID()},
            body: category.getFormData(),
            config: XhrUtilities.configHttpRequest,
            extract: XhrUtilities.extractResponse,
        });
    };

    this.addSubmitListener = function (form) {
        form.addEventListener('submit', function(event) {
            event.preventDefault();
            event.stopPropagation();

            submittedFormValid = form.checkValidity();
            form.classList.add('was-validated');
        }, false);
    };

    function getFirstApplicableCategory(order) {
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].isApplicable(order)) {
                activeCategory = categories[i];
                return activeCategory;
            }
        }
    }

    function init(compalaintsData) {
        activeCategory = undefined;
        for (let [type, data] of Object.entries(compalaintsData)) {
            let category = new ComplaintCategoryTypeMap[type](type, data);
            categories.push(category);
        }
    }

    init(compalaintsData);

};

export default ComplaintFormModel;
