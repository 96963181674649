let Bootstrap = {
    getBootstrapEnv: function() {
        let envs = ['xs', 'sm', 'md', 'lg', 'xl'];

        let el = document.createElement('div');
        document.body.appendChild(el);

        let curEnv = envs.shift();

        for (let env of envs.reverse()) {
            el.classList.add(`d-${env}-none`);

            if (window.getComputedStyle(el).display === 'none') {
                curEnv = env;
                break;
            }
        }

        document.body.removeChild(el);
        return curEnv;
    },
    isTabletView: function() {
        return ['xs', 'sm', 'md'].includes(this.getBootstrapEnv());
    },
    isMobileView: function() {
        return ['xs', 'sm'].includes(this.getBootstrapEnv());
    },
    displayTooltip: function (domElement, parameters) {
        $('.bs-tooltip', $(domElement)).tooltip($.extend({html: true}, parameters ? parameters : {}));
    },
    hideTooltip: function (domElement) {
        $('.bs-tooltip', $(domElement)).tooltip('hide');
    },
    getSpinner: function () {
        return '<div class="spinner-border spinner-abc text-info" role="status"><span class="sr-only">' +_t('blockui.wait').children + '</span></div>';
    },
    blockUI: function () {
        const elemDivContainer = document.createElement('div');
        elemDivContainer.classList.add('block-ui');
        elemDivContainer.classList.add('block-container');
        const elemDivMsg = document.createElement('div');
        elemDivMsg.classList.add('block-ui');
        elemDivMsg.classList.add('block-msg');
        elemDivMsg.innerHTML = this.getSpinner();
        document.body.appendChild(elemDivContainer);
        document.body.appendChild(elemDivMsg);
    },
    unblockUI : function () {
        const collection = document.querySelectorAll('.block-ui');

        for (const elem of collection) {
            elem.remove();
        }
    },
    getScrollBarWidth: function () {
        return window.innerWidth - document.body.offsetWidth;
    },
    isScrollShown: function (el) {
        return el.scrollHeight > window.innerHeight;
    },
    preventEscClose: function (e) {
        if (27 === e.keyCode) {
            e.stopImmediatePropagation();
        }
    },
};

export default Bootstrap;
