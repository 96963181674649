import InvalidFeedbackMessage from '../../../widgets/InvalidFeedbackMessage';
import HiddenSubmitButton from '../../../widgets/HiddenSubmitButton';
import Bootstrap from '../../../extentions/Bootstrap';

export default function() {
    return {
        oncreate: function (vnode) {
            Bootstrap.displayTooltip(vnode.dom);
            vnode.attrs.form.addSubmitListener($(vnode.dom).find('form[name="' + vnode.attrs.form.getFormName() + '"]')[0]);
        },
        onbeforeremove: function (vnode) {
            Bootstrap.hideTooltip(vnode.dom);
        },
        view: function(vnode) {
            let order = vnode.attrs.order;

            //console.log('order view', order.isErrorMessageExists());

            function submit(order) {
                $('form[name="' + vnode.attrs.form.getFormName() + '"]').find(':submit').click();

                if (vnode.attrs.form.isSubmittedFormValid()) {
                    order.clearErrorMessage();
                    Bootstrap.blockUI();
                    order.load().then(function() {
                        Bootstrap.unblockUI();
                        if (order.isErrorMessageExists()) {
                            vnode.attrs.showOrderNotFoundDialog(order.getErrorMessage());
                            order.setUID(null);
                        } else {
                            setTimeout(function() {
                                $(window).scrollTop(0);
                            }, 500);
                        }
                    });
                }
            }

            let submitOnEnter = function (e) {
                if (13 === e.keyCode) {
                    submit(order);
                }
            };

            return (
                <div className="form-container">
                    <h5 className="text-uppercase font-bold pb-1">{_t('login.header.details')}</h5>
                    <h1 className="mb-2">{_t('login.header.login')}</h1>
                    <p className="mb-0 text-uppercase font-color-title font-size-small font-bold">{_t('login.header.ordernumber')}</p>
                    <form name={vnode.attrs.form.getFormName()} novalidate>
                        <div className="mb-2 input-group input-container-append">
                            <input className="form-control h-100 shadow-none" required="required" placeholder={_t('login.placeholder.orderuid').children}
                                value={order.getUID()}
                                onkeypress={(e) => {return /[a-zA-Z0-9]/i.test(e.key);}}
                                oninput={(e) => {order.setUID(e.target.value.replace(/[^a-zA-Z0-9]+/g, ''));}}
                                onkeydown={(e) => {submitOnEnter(e);}}/>
                            <div className="input-group-append">
                                <div className="input-group-text justify-content-center"><span className="icon-info bs-tooltip"
                                    data-toggle="tooltip" data-placement="top" data-container="body"
                                    data-original-title={ _t('message_0069').children }/></div>
                            </div>
                            <InvalidFeedbackMessage message={_t('form.errormessage.empty')}/>
                        </div>
                        <HiddenSubmitButton/>
                    </form>

                    <div className="button-container">
                        <button className="btn btn-info-abc btn-block text-uppercase h-100 d-flex align-items-center justify-content-center shadow-none" onclick={() => submit(order)} >
                            <span className="d-inline-block icon-margin-right">{_t('login.cta.login')}</span>
                            <span className="d-inline-block icon-arrow-login" />
                        </button>
                    </div>

                    <div className="pt-3">
                        <a href="#" className="font-color-selected" onclick={vnode.attrs.lostLoginRequestDialog}>{_t('login.forgotnumber')}</a>
                    </div>
                </div>
            );
        },
    };
}
