import SideModalDialog from '../../../../widgets/SideModalDialog';
import Form from './form/Form';
import Header from './form/Header';
import Footer from './form/Footer';
import DialogWarning from './DialogWarning';
import DialogSuccess from './DialogSuccess';
import DialogError from './DialogError';
import Bootstrap from '../../../../extentions/Bootstrap';
import XhrUtilities from '../../../../extentions/XhrUtilities';

export default function () {
    let ContainerDialog;
    let SuccessDialog;
    let ErrorDialog;
    let WarningDialog;

    let SubmitDialog = function (order) {
        let formModel = order.getDeleteOrderFormModel();

        let formName = formModel.getOrderDeleteModel().getType();
        $('form[name="' + formName + '"]').find(':submit').click();

        if (formModel.isSubmittedFormValid()) {
            ContainerDialog.hide();
            Bootstrap.blockUI();
            formModel.submit(order).then(function(response) {
                Bootstrap.unblockUI();
                if (201 === response.status) {
                    SuccessDialog && SuccessDialog.show();
                } else if (302 === response.status) {
                    window.location.replace(response.body);
                } else {
                    ErrorDialog && ErrorDialog.show(response.body ? response.body : XhrUtilities.getGeneralErrorMessage());
                }
            });
        }
    };

    let hideScroll = function () {
        document.body.classList.add('hide-scroll');
    };

    let showScroll = function () {
        document.body.classList.remove('hide-scroll');
    };

    let closeContainerDialogWithoutConfirmation = function () {
        ContainerDialog.hide();
        showScroll();
    };
    return {
        oninit: function () {
            ContainerDialog = new SideModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        view: function (vnode) {
            let order = vnode.attrs.order;
            let logout = function () {
                order.unload();
            };
            if (! ContainerDialog.isVisible()) {
                return (
                    <div>
                        <DialogWarning hideScroll={hideScroll} showScroll={showScroll} oncreate={function (vnode) {WarningDialog = vnode.state;}}
                            onCancel={ContainerDialog.show} onConfirm={function () {ContainerDialog.show();setTimeout(function () {SubmitDialog(order)}, 600);}}
                            order={order}
                        />
                        <DialogSuccess hideScroll={hideScroll} showScroll={showScroll} onCancel={() => logout()} oncreate={function (vnode) {SuccessDialog = vnode.state;}}/>
                        <DialogError hideScroll={hideScroll} showScroll={showScroll} oncreate={function (vnode) {ErrorDialog = vnode.state;}}/>
                    </div>
                );
            }

            let header = <Header onCancel={() => closeContainerDialogWithoutConfirmation()} containerDialog={ContainerDialog} />;
            let body = <Form order={order}/>;
            let footer = <Footer warningDialog={WarningDialog} containerDialog={ContainerDialog} onCancel={() => closeContainerDialogWithoutConfirmation()}/>;
            return (
                <div>
                    <DialogWarning hideScroll={hideScroll} showScroll={showScroll} oncreate={function (vnode) {WarningDialog = vnode.state;}}
                        onCancel={ContainerDialog.show} order={order} onConfirm={function () {ContainerDialog.show();setTimeout(function () {SubmitDialog(order)}, 600);}}/>
                    <DialogSuccess hideScroll={hideScroll} showScroll={showScroll} onCancel={() => logout()} oncreate={function (vnode) {SuccessDialog = vnode.state;}}/>
                    <DialogError hideScroll={hideScroll} showScroll={showScroll} oncreate={function (vnode) {ErrorDialog = vnode.state;}}/>
                    <ContainerDialog hideScroll={hideScroll} outsideClickClose={true} onCancel={() => closeContainerDialogWithoutConfirmation()}
                        header={header} body={body} footer={footer} modalClass={vnode.attrs.order.getDeleteOrderFormModel().getOrderDeleteClass()}/>
                </div>
            );
        },
    };
}
