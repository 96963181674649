import FormContainer from './FormContainer';
import backGroundImageSe from '../../../images/banner-myabc-login-mobile-se.jpg';
import backGroundImageDk from '../../../images/banner-myabc-login-mobile-dk.jpg';
import Country from '../../../extentions/Country';

export default function() {
    return {
        view: function(vnode) {
            return (
                <div className="w-100 form-mobile-container">
                    <img className="w-100" src={(Country.isSe() ? backGroundImageSe : backGroundImageDk)}/>
                    <div className="w-100 rounded form">
                        <div className="w-100 p-2">
                            <FormContainer order={vnode.attrs.order} form={vnode.attrs.form} showOrderNotFoundDialog={vnode.attrs.showOrderNotFoundDialog} lostLoginRequestDialog={vnode.attrs.lostLoginRequestDialog}/>
                        </div>
                    </div>
                </div>
            );
        },
    };
}
