import Bootstrap from '../extentions/Bootstrap';

export default function () {
    let currentOption;
    let currentId;

    return {
        oncreate: function(vnode) {
            vnode.attrs.onChange(currentId);
        },
        view: function (vnode) {
            let IconComponent = vnode.attrs.iconComponent ? vnode.attrs.iconComponent : null;

            currentOption = currentOption ? currentOption : (vnode.attrs.selected ? vnode.attrs.options[vnode.attrs.selected] : _.values(vnode.attrs.options)[0]);
            currentId     = currentId ? currentId : (vnode.attrs.selected ? vnode.attrs.selected : _.keys(vnode.attrs.options)[0]);

            let getCurrentOption = function () {
                return currentOption;
            };

            let getOptionPrefix = function () {
                return vnode.attrs.optionPrefix ? vnode.attrs.optionPrefix : '';
            };

            let dropdownOnchange = function (option, id) {
                currentId     = id;
                currentOption = option;

                vnode.attrs.onChange(currentId);

                if (vnode.attrs.focusClass) {
                    $('.' + vnode.attrs.focusClass).focus();
                }
            };

            return (
                <div className="dropdown-container input-group">

                    <button className="btn btn-light btn-block h-100 d-flex align-items-center justify-content-between input-border shadow-none text-left" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="d-flex align-items-center">
                            {IconComponent ? (<IconComponent />) : '' }
                            <span className="d-inline-block">{_t(getOptionPrefix() + getCurrentOption())}</span>
                        </span>
                        <span className="d-inline-block">
                            <span className="d-block icon-dropdown" />
                        </span>
                    </button>

                    <div className="dropdown-menu dropdown-menu-left w-100">
                        {_.map(vnode.attrs.options, function (option, id) {
                            return <a href="#" onclick={() => dropdownOnchange(option, id)} className="dropdown-item font-size-large font-bold">{_t(getOptionPrefix() + option)}</a>;
                        })}
                    </div>
                </div>
            );
        },
    };
}
