import ComplaintCategoryType from './ComplaintCategoryType';
import WrongSize from '../../component/page/order/complaint/category/WrongSize';
import DefectiveProduct from '../../component/page/order/complaint/category/DefectiveProduct';
import ReturnProduct from '../../component/page/order/complaint/category/ReturnProduct';

let ComplaintCategoryTypeComponentMap = {};

ComplaintCategoryTypeComponentMap[ComplaintCategoryType.WRONG_SIZE] = WrongSize();
ComplaintCategoryTypeComponentMap[ComplaintCategoryType.DEFECTIVE_PRODUCT] = DefectiveProduct();
//ComplaintCategoryTypeComponentMap[ComplaintCategoryType.RETURN_PRODUCT] = ReturnProduct();

export default ComplaintCategoryTypeComponentMap;
