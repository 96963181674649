import OrderInvoiceType from './OrderInvoiceType';
import InvoiceShowButton from '../../../component/page/order/button/InvoiceShowButton';
import InvoicePostponeButton from '../../../component/page/order/button/InvoicePostponeButton';

let OrderInvoiceTypeButtonComponentMap = {};

OrderInvoiceTypeButtonComponentMap[OrderInvoiceType.NOT_INVOICED] = null;
OrderInvoiceTypeButtonComponentMap[OrderInvoiceType.INVOICED] = InvoiceShowButton;
OrderInvoiceTypeButtonComponentMap[OrderInvoiceType.UNPAID] = InvoicePostponeButton;

export default OrderInvoiceTypeButtonComponentMap;
