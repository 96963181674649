import Layout from '../component/Layout';
import LoginPage from '../component/page/Login';
import OrderPage from '../component/page/Order';

let Router = function (orderModel) {

    function reRouteByOrderState() {
        let result;
        if (orderModel.isLoaded()) {
            (result = m.route.get() === '/login' && ! orderModel.isDeleted()) && m.route.set('/order');
        } else {
            (result = m.route.get() === '/order') && m.route.set('/login');
        }
        return result;
    }

    const routes = {
        '/': {
            onmatch: function() {
                if (orderModel.isDeleted()) {
                    orderModel.setDeletedDialogue(true);
                    orderModel.unload();
                }

                let uid = orderModel.getOrderCookies('UID');
                m.route.set(uid ? '/order' : '/login');
            },
        },
        '/login': {
            render: function (vnode) {
                return reRouteByOrderState() ? vnode : m(Layout, {order: orderModel, page: LoginPage});
            },
        },
        '/order': {
            render: function (vnode) {
                return reRouteByOrderState() ? vnode : m(Layout, {order: orderModel, page: OrderPage});
            },
        },
    };

    this.navigate = function (mountPoint) {
        m.route(mountPoint, orderModel.isLoaded() && ! orderModel.isDeleted() ? '/order' : '/login', routes);
    };

    m.route.prefix = '';

};

export default Router;
