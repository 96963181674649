import XhrUtilities from '../../extentions/XhrUtilities';
import OrderDelete from '../order/OrderDelete';

let OrderDeleteFormModel = function (deleteOrderFormData) {

    let categories = [];
    let orderDeleteClass = 'modal-delete-order';
    let submittedFormValid;
    let orderDeleteModel;

    this.getCategories = function () {
        return categories;
    };

    this.getOrderDeleteClass = function () {
        return orderDeleteClass;
    };

    this.isSubmittedFormValid = function () {
        return submittedFormValid;
    };

    this.getOrderDeleteModel = function () {
        return orderDeleteModel;
    };

    this.submit = function (order) {
        return m.request({
            method: 'POST',
            url: orderDeleteModel.getPath() + '/:uid',
            params: {uid: order.getUID()},
            body: orderDeleteModel.getFormData(),
            config: XhrUtilities.configHttpRequest,
            extract: XhrUtilities.extractResponse,
        });
    };

    this.addSubmitListener = function (form) {
        form.addEventListener('submit', function(event) {
            event.preventDefault();
            event.stopPropagation();

            submittedFormValid = form.checkValidity();
            form.classList.add('was-validated');
        }, false);
    };


    function init(deleteOrderFormData) {
        orderDeleteModel = new OrderDelete(deleteOrderFormData);
    }

    init(deleteOrderFormData);

};

export default OrderDeleteFormModel;
