import Bootstrap from '../../../../../extentions/Bootstrap';

export default function () {
    return {
        view: function (vnode) {
            let order = vnode.attrs.order;
            let formModel = order.getComplaintFormModel();
            let content = formModel.getActiveCategory(order).getComponent(order, vnode.attrs.auxModal);

            return (<div>
                <div>
                    <h4 className="font-bold">{ _t('complaint.header.category') }</h4>
                </div>
                <div className="d-flex nav-list-container">
                    {formModel.getCategories().map(function (categoryModel) {
                        return categoryModel.isApplicable(order) ?
                            <div className={'button-container ' + (formModel.isActive(categoryModel) ? 'active' : '')}>
                                <button
                                    className={'d-flex btn w-100 h-100 align-items-center justify-content-center border-0 ' + (formModel.isActive(categoryModel) ? 'btn-dark-abc' : 'btn-whiteness-abc')}
                                    onclick={() => formModel.setActiveCategory(categoryModel)}>
                                    <span>{_t(categoryModel.getTitle())}</span>
                                </button>
                            </div>
                            : [];
                    })}
                </div>
                <div className="pt-3 pb-1">
                    <h4 className="font-bold">{ _t('complaint.header.form') }</h4>
                </div>

                <div className="tab-content">{content}</div>
            </div>);
        },
    };
}
