import OrderItemLabel from './OrderItemLabel';
import Bootstrap from '../../../extentions/Bootstrap';

export default function() {
    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            let status = order.getStatus();
            let StatusComponent = status.getComponent();
            let StatusComponentButton = status.getComponentButton();
            let isStatusComponentButton = !! StatusComponentButton;
            return (
                <div className="row ml-0 item-status cart-style">
                    <div className={(isStatusComponentButton ? 'col-sm-7 col-12' : 'col-12') + ' px-2'}>
                        <div className={'d-flex h-100 w-100 align-items-center' + (Bootstrap.isMobileView() ? (isStatusComponentButton ? ' pt-2' : ' py-2') : '')}>
                            <div className="icon icon-status" />
                            <div className="flex-fill pl-1 line-height-middle">
                                <OrderItemLabel label={_t('orderstatus.label.info')}/>
                                <StatusComponent order={order} />
                            </div>
                        </div>
                    </div>
                    <div className={(isStatusComponentButton ? 'col-sm-5 col-12' : 'd-none') + ' px-2'}>
                        <div className="d-flex h-100 align-items-center justify-content-end">
                            {isStatusComponentButton ? <StatusComponentButton order={order} /> : '' }
                        </div>
                    </div>
                </div>
            );
        },
    };
}
