import DialogChangeStudent from '../modal/DialogChangeStudent';
import DialogChangeOther from '../modal/DialogChangeOther';
import OrderStatusButton from '../../../../widgets/OrderStatusButton';

export default function () {
    let changeDialog;
    return {
        view: function (vnode) {
            let order = vnode.attrs.order;
            return (
                <div className="w-100">
                    <OrderStatusButton disabled={order.isLocked()} title={order.isLocked() ? _t('button.orderedit.title').children : null} onClick={() => changeDialog.show(order)} message={_t('message_0035')}/>
                    {order.getShop().getType() === 'student'
                        ? <DialogChangeStudent oncreate={function (vnode) {
                            changeDialog = vnode.state;
                        }} order={order}/>
                        : <DialogChangeOther oncreate={function (vnode) {
                            changeDialog = vnode.state;
                        }} order={order}/>
                    }
                </div>
            );
        },
    };
}
