import OrderRowModel from './OrderRow';
import OrderInvoiceModel from './invoice/OrderInvoice';
import OrderCustomerModel from './OrderCustomer';
import ComplaintFormModel from '../form/ComplaintForm';
import OrderDeleteForm from '../form/OrderDeleteForm';
import OrderShopModel from './OrderShop';
import OrderStatus from './status/OrderStatus';
import XhrUtilities from '../../extentions/XhrUtilities';

let OrderModel = function (Cookies, Translator, orderData) {

    orderData = orderData || null;

    let uid = '';
    let errorMessage;

    let status;
    let rows = [];
    let invoice;
    let customer;
    let shop;
    let key = 0;
    let self = this;
    let deletedDialogue = false;

    let complaintFormModel = new ComplaintFormModel(window.config.complaints);
    let deleteOrderFormModel = new OrderDeleteForm(window.config.deleteOrderFormData);

    orderData && init();

    this.getKey = function () {
        return key;
    };

    this.setKey = function (value) {
        key = value;
    };

    this.getComplaintFormModel = function () {
        return complaintFormModel;
    };

    this.getDeleteOrderFormModel = function () {
        return deleteOrderFormModel;
    };

    this.setUID = function (_uid) {
        uid = _uid;
    };

    this.getUID = function () {
        return uid;
    };

    this.isLoaded = function () {
        return !! orderData;
    };

    this.isLocked = function () {
        return status.isLocked();
    };

    this.getLockDate = function () {
        return status.getLockDate();
    };

    this.isInvoiced = function () {
        return invoice.isInvoiced();
    };

    this.getStatus = function () {
        return status;
    };

    this.getListedSum = function () {
        return orderData.listedSum;
    };

    this.getDuplicatedOrders = function () {
        return orderData.duplicatedOrders;
    };

    this.getSavings = function () {
        return orderData.savings;
    };

    this.getInvoice = function () {
        return invoice;
    };

    this.getCustomer = function () {
        return customer;
    };

    this.getShop = function () {
        return shop;
    };

    this.isDeleted = function () {
        return orderData.isDeleted === 'yes';
    };

    this.isDeletedDialogue = function () {
        return deletedDialogue;
    };

    this.setDeletedDialogue = function (dDialogue) {
        deletedDialogue = dDialogue;
    };

    this.getChangeLink = function () {
        return orderData.changeLink;
    };

    this.getHatLink = function () {
        return orderData.hatLink;
    };

    this.getHatSize = function () {
        let size = 0;
        let row = this.getRowsByType('hats', true);
        if (row.length) {
            size = parseInt(row[0].getProduct().getDimensionByType(shop.getHatSizeDimension()).getVariant().getValue(true));
        }
        return size;
    };

    /**
     * @returns {[]}
     */
    this.getRows = function () {
        return rows;
    };

    /**
     * @param {String|Array} type
     * @param {Boolean} includeInvisible
     * @returns {Array}
     */
    this.getRowsByType = function (type, includeInvisible) {
        type = Array.isArray(type) ? type : [type];
        return _.filter(rows, function (row) {
            return type.indexOf(row.getProductType()) !== -1 && (row.isVisible() || includeInvisible);
        });
    };

    this.getPackageRow = function (packageRow) {
        return _.filter(rows, function (row) {
            return packageRow.getId() === row.getParentOrderRow();
        });
    };

    this.isRowInPackage = function (orderRow) {
        let packageRows = self.getRowsByType('packages');
        let result = false;
        for (let i = 0; i < packageRows.length; i++) {
            if (packageRows[i].getId() === orderRow.getParentOrderRow()) {
                result = true;
                break;
            }
        }
        return result;
    };

    this.getErrorMessage = function () {
        return errorMessage;
    };

    this.clearErrorMessage = function () {
        errorMessage = null;
    };

    this.isErrorMessageExists = function () {
        return !! errorMessage;
    };

    this.isNoHat = function () {
        return this.getRowsByType('hats', true).length === 0;
    };

    function initRows() {
        rows = [];
        for (let [key, value] of Object.entries(orderData.orderRows)) {
            rows.push(new OrderRowModel(Translator, value, self));
        }
    }

    function init() {
        status = new OrderStatus(Translator, orderData);
        invoice = new OrderInvoiceModel(Translator, orderData.invoice);
        customer = new OrderCustomerModel(orderData.invoiceAddress);
        shop = new OrderShopModel(Translator, orderData.schoolWebShop);
        uid = orderData.uid;
        try {
            initRows();
        } catch (e) {
            unloadOrder();
            errorMessage = XhrUtilities.getGeneralErrorMessage();
        }
    }

    this.postponeInvoice = function(date, successCallback, errorCallback) {
        return m.request({
            method: 'POST',
            url: '/sc/postpone/:uid',
            params: {uid: uid},
            body: {respite_date: date},
            config: XhrUtilities.configHttpRequest,
            extract: XhrUtilities.extractResponse,
        }).then(
            function(response) {
                if (200 === response.status) {
                    successCallback && successCallback(() => {invoice.initFromData(response.body);});
                } else {
                    errorCallback && errorCallback(response.body ? response.body : XhrUtilities.getGeneralErrorMessage());
                }
            },
        );
    };

    this.load = function() {
        return m.request({
            method: 'GET',
            url: '/order/:uid',
            params: {uid: uid},
            config: XhrUtilities.configHttpRequest,
            extract: XhrUtilities.extractResponse,
        }).then(function(response) {
            if (200 === response.status) {
                orderData = response.body;
                if (self.isLoaded() && ! self.isDeleted()) {
                    init();
                    Cookies.setCookie('UID', uid, {expires: window.config.uidCookieLifetime});
                } else {
                    deletedDialogue = true;
                    unloadOrder();
                }
            } else if (302 === response.status) {
                window.location.replace(response.body);
            } else if ([401, 403, 404].includes(response.status)) {
                errorMessage = response.body;
            } else {
                errorMessage = XhrUtilities.getGeneralErrorMessage();
            }
        });
    };

    this.unload = function () {
        unloadOrder();
    };

    this.isTherePriceForVar2VariantSubtypeHatsForAnyRow = function () {
        let result = false;
        for (const orderRow of self.getRows()) {
            if (orderRow.isTherePriceForVar2VariantSubtypeHats()){
                result = true;
                break;
            }
        }
        return result;
    };

    this.getOrderCookies = function (cookie) {
        return Cookies.getCookie('UID');
    };

    function unloadOrder () {
        orderData = null;
        uid = '';
        Cookies.deleteCookie('UID');
    }
};

export default OrderModel;
