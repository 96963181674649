import ComplaintCategoryType from './ComplaintCategoryType';
import ComplaintWrongSizeModel from './ComplaintWrongSize';
import ComplaintDefectiveProductModel from './ComplaintDefectiveProduct';
import ComplaintReturnProductModel from './ComplaintReturnProduct';

let ComplaintCategoryTypeMap = {};

ComplaintCategoryTypeMap[ComplaintCategoryType.WRONG_SIZE] = ComplaintWrongSizeModel;
ComplaintCategoryTypeMap[ComplaintCategoryType.DEFECTIVE_PRODUCT] = ComplaintDefectiveProductModel;
//ComplaintCategoryTypeMap[ComplaintCategoryType.RETURN_PRODUCT] = ComplaintReturnProductModel;

export default ComplaintCategoryTypeMap;
