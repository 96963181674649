let OrderCustomerModel = function (customerData) {
    this.getName = function() {
        return [customerData.firstName, customerData.lastName].join(' ');
    };

    this.getFirstName = function() {
        return customerData.firstName;
    };

    this.getAddress = function () {
        return [
            [customerData.streetLine1, customerData.streetLine2].join(' ').trim(),
            [customerData.postalCode, customerData.postalCity].join(' ').trim(),
        ].join(', ');
    };

    this.getEmail = function () {
        return customerData.email;
    };

    this.getPhone = function () {
        return customerData.phone;
    };

};

export default OrderCustomerModel;
