import Tooltip from '../../../../widgets/Tooltip';
import DateHelper from '../../../../extentions/DateHelper';

export default function () {
    return {
        view: function (vnode) {
            let order = vnode.attrs.order;
            let status = vnode.attrs.order.getStatus();
            let date = order.getLockDate() ? DateHelper.getDateInSeFormat(new Date(order.getLockDate())) : _t('message_0033').children;
            return (<div>
                <p className="m-0 font-color-title font-bold font-size-big d-flex align-items-center">
                    <span className="d-inline-block">{status.getTextTitle({date: date})}</span>
                    <Tooltip message={status.getTooltipTitle().children}/>
                </p>
            </div>);
        },
    };
}
