import DimensionVariantModel from './DimensionVariant';

let ProductDimensionModel = function (Translator, dimensionData, variantValue) {

    let variant = new DimensionVariantModel(Translator, variantValue, dimensionData.variants[variantValue]);

    this.getName = function () {
        return Translator.getTranslation(dimensionData.translations);
    };

    this.getVariant = function () {
        return variant;
    };

};

export default ProductDimensionModel;
