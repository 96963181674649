//
// @WARNING: all the classes and layout used are only to illustrate
// TODO: apply real slicing
//
export default function() {
    return {
        view: function() {
            return (
                <tr>
                    <td colspan="3">
                        <span className="col-12 d-block font-color-title font-bold font-size-big">{_t('order.header.addons')}</span>
                    </td>
                </tr>
            );
        },
    };
}
