import SideModalDialog from '../../../../widgets/SideModalDialog';
import Form from './form/Form';
import Header from './form/Header';
import Footer from './form/Footer';
import DialogCancel from './DialogCancel';
import DialogSuccess from './DialogSuccess';
import DialogError from './DialogError';
import DialogIFrame from './category/DialogIFrame';
import Bootstrap from '../../../../extentions/Bootstrap';
import XhrUtilities from '../../../../extentions/XhrUtilities';

export default function () {
    let ContainerDialog;
    let SuccessDialog;
    let ErrorDialog;
    let CancelDialog;
    let iFrameDialog;

    let SubmitDialog = function (order) {
        let formName = order.getComplaintFormModel().getActiveCategory(order).getType();
        $('form[name="' + formName + '"]').find(':submit').click();

        let formModel = order.getComplaintFormModel();
        if (formModel.isSubmittedFormValid()) {
            ContainerDialog.hide();
            Bootstrap.blockUI();
            formModel.submit(order).then(function(response) {
                Bootstrap.unblockUI();
                if (201 === response.status) {
                    SuccessDialog && SuccessDialog.show();
                } else if (302 === response.status) {
                    window.location.replace(response.body);
                } else {
                    ErrorDialog && ErrorDialog.show(response.body ? response.body : XhrUtilities.getGeneralErrorMessage());
                }
            });
        }
    };

    let hideScroll = function () {
        document.body.classList.add('hide-scroll');
    };

    let showScroll = function () {
        document.body.classList.remove('hide-scroll');
    };

    return {
        oninit: function () {
            ContainerDialog = new SideModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return (
                    <div>
                        <DialogCancel hideScroll={hideScroll} showScroll={showScroll} oncreate={function (vnode) {CancelDialog = vnode.state;}}
                            onCancel={ContainerDialog.show} onConfirm={() => vnode.attrs.onCancel}
                        />
                        <DialogSuccess hideScroll={hideScroll} showScroll={showScroll} oncreate={function (vnode) {SuccessDialog = vnode.state;}}/>
                        <DialogError hideScroll={hideScroll} showScroll={showScroll} oncreate={function (vnode) {ErrorDialog = vnode.state;}}/>
                        <DialogIFrame target="https://www.youtube.com/embed/0aBVfmfrq_M?autoplay=1" width="560" height="315" oncreate={function (vnode) {
                            iFrameDialog = vnode.state;
                            iFrameDialog.getContainer = function () {return ContainerDialog;};
                        }}  onClose={ContainerDialog.show}/>
                    </div>
                );
            }
            let order = vnode.attrs.order;
            let header = <Header cancelDialog={CancelDialog} containerDialog={ContainerDialog} />;
            let body = <Form order={order} auxModal={iFrameDialog}/>;
            let footer = <Footer cancelDialog={CancelDialog} containerDialog={ContainerDialog} order={order} submitDialog={SubmitDialog}/>;
            return (
                <div>
                    <DialogCancel hideScroll={hideScroll} showScroll={showScroll} oncreate={function (vnode) {CancelDialog = vnode.state;}}
                        onCancel={ContainerDialog.show} onConfirm={() => ContainerDialog.cancel(vnode.attrs.onCancel)}/>
                    <DialogSuccess hideScroll={hideScroll} showScroll={showScroll} oncreate={function (vnode) {SuccessDialog = vnode.state;}}/>
                    <DialogError hideScroll={hideScroll} showScroll={showScroll} oncreate={function (vnode) {ErrorDialog = vnode.state;}}/>
                    <DialogIFrame target="https://www.youtube.com/embed/0aBVfmfrq_M?autoplay=1" width="560" height="315" oncreate={function (vnode) {
                        iFrameDialog = vnode.state;
                        iFrameDialog.getContainer = function () {return ContainerDialog;};
                    }}  onClose={ContainerDialog.show}/>
                    <ContainerDialog hideScroll={hideScroll} outsideClickClose={true} onCancel={() => CancelDialog.show(ContainerDialog)}
                        header={header} body={body} footer={footer} modalClass={vnode.attrs.order.getComplaintFormModel().getComplaintClass()}/>
                </div>
            );
        },
    };
}
