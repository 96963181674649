import OptionSelector from '../../../../../widgets/OptionSelector';
import CheckboxSelector from '../../../../../widgets/CheckboxSelector';
import Textarea from '../../../../../widgets/Textarea';
import Country from '../../../../../extentions/Country';
import InputLabel from '../../../../../widgets/InputLabel';
import HiddenSubmitButton from '../../../../../widgets/HiddenSubmitButton';

export default function () {
    return {
        oncreate: function (vnode) {
            let formModel = vnode.attrs.order.getComplaintFormModel();
            formModel.addSubmitListener($(vnode.dom).find('form[name="' + vnode.attrs.categoryModel.getType() + '"]')[0]);
        },
        view: function (vnode) {
            let order = vnode.attrs.order;
            let model = vnode.attrs.categoryModel;

            return (
                <div className="form-container">
                    <form name={model.getType()} novalidate>
                        <div>
                            <InputLabel label={_t('complaint.label.product')}/>
                            <OptionSelector options={model.getProducts(order)} selected={model.getProduct()} onChange={model.setProduct} focusClass={vnode.attrs.order.getComplaintFormModel().getComplaintClass()}/>
                        </div>
                        <div className="pt-2">
                            <InputLabel label={_t('complaint.label.productreason')}/>
                            <OptionSelector options={model.getReasons()} selected={model.getReason()} onChange={model.setReason} focusClass={vnode.attrs.order.getComplaintFormModel().getComplaintClass()}/>
                        </div>
                        <div className="pt-2">
                            <InputLabel label={_t('complaint.label.returndescription')}/>
                            <Textarea onChange={model.setDescription} required="true" value={model.getDescription()} placeholder={_t('complaint.wrongsize.textarea.placeholder').children} />
                        </div>
                        <div>
                            { Country.isSe()
                                ? <CheckboxSelector label={ _t('complaint.label.returnfee') } onChange={model.setReturnFee} checked={model.getReturnFee()} />
                                : model.setReturnFee(true)
                            }
                        </div>
                        <HiddenSubmitButton />
                    </form>
                </div>
            );
        },
    };
}
