import ProductDimensionModel from './ProductDimension';

let ProductModel = function (Translator, productData, dimensionsData) {

    let dimensions = {};

    this.getArticleNumber = function () {
        return productData.articleNumber;
    };

    this.getName = function () {
        return productData.name;
    };

    this.getDimensions = function() {
        return dimensions;
    };

    this.getDimensionByType = function (type) {
        return typeof dimensions[type] !== 'undefined'
            ? dimensions[type]
            : []
        ;
    };

    this.hasTextPrint = function () {
        return productData.textprint === 'yes';
    };

    this.isVisible = function () {
        return productData.visibility !== 'technical';
    };

    this.getSubtype = function () {
        return productData.subtype;
    };

    this.hasVariants = function () {
        return Object.keys(dimensions).length > 0;
    };

    this.getVariants = function () {
        let values = [];
        _.each(dimensions, function (dimension) {
            values.push(dimension.getVariant().getValue());
            values.push(', ');
        });
        values.pop();
        return values.join('');
    };

    _.each(dimensionsData, function(variantValue, type) {
        if (variantValue && variantValue.toUpperCase() !== 'NONE') {
            dimensions[type] = new ProductDimensionModel(Translator, productData.dimensions[type], variantValue);
        }
    });
};

export default ProductModel;
