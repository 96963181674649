import ImageCropper from '../../../../../widgets/ImageCropper';

let DialogBody = function () {
    return {
        view: function (vnode) {
            let model = vnode.attrs.model;
            let clickInput = function () {
                $(vnode.dom).find('input.inputfile').click();
            };

            return (
                <div>
                    <div>
                        <p className="font-color-title">{_t((model.canCrop() ? 'photo.text.crop' : 'photo.text.upload'), {size: (model.getMaxPhotoSize()/1024/1024)})}</p>
                    </div>
                    {model.canCrop()
                        ? <div>
                            <div className="">
                                <div className="crop-container pb-1">
                                    <ImageCropper src={model.getUrl()} imageFile={model.getImageFile()} oncreate={function (vnode) {
                                        model.setCropper(vnode.state);
                                    }}/>
                                </div>
                            </div>
                            <div className="button-container">
                                <button
                                    className='btn btn-secondary-abc text-uppercase font-size-small h-100 w-100 d-flex align-items-center justify-content-center'
                                    onclick={() => clickInput()}>
                                    <span>{_t(model.getSelectText())}</span>
                                </button>
                            </div>
                        </div>
                        :
                        <div className={'upload-container w-100 d-flex justify-content-center align-items-center ' + (model.getError() ? 'border-error' : '')} onclick={() => clickInput()}>
                            <span className="font-bold font-size-biggest font-color-primary"><u>{_t('photo.cta.select')}</u></span>
                        </div>
                    }
                    {model.getError()
                        ? <div className="font-color-danger font-size-smallest text-right">
                            { _t(model.getError(), {size: (model.getMaxPhotoSize()/1024/1024)}) }
                        </div>
                        : ''
                    }
                    <input className="inputfile d-none" type="file" accept={model.getPhotoFileTypes()} onchange={(e) => {model.onFileSelected(e);}}/>
                </div>
            );
        },
    };
};

export default DialogBody;
