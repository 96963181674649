import OrderInvoiceType from './OrderInvoiceType';
import NotInvoiced from '../../../component/page/order/invoice/NotInvoiced';
import Invoiced from '../../../component/page/order/invoice/Invoiced';
import Unpaid from '../../../component/page/order/invoice/Unpaid';

let OrderInvoiceTypeComponentMap = {};

OrderInvoiceTypeComponentMap[OrderInvoiceType.NOT_INVOICED] = NotInvoiced;
OrderInvoiceTypeComponentMap[OrderInvoiceType.INVOICED] = Invoiced;
OrderInvoiceTypeComponentMap[OrderInvoiceType.UNPAID] = Unpaid;

export default OrderInvoiceTypeComponentMap;
