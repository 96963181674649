export default function () {

    return {
        view: function (vnode) {
            let onClick = function () {
                vnode.attrs.modal.getContainer().hide();
                vnode.attrs.modal.show();
            };

            return (
                <div className="fitting-video-block w-100 d-flex align-items-center" onclick={onClick}>
                    <div className="fitting-video-box w-100 d-flex">
                        <div className="fitting-video-thumb h-100 d-flex justify-content-center align-items-center">
                            <span className="d-block icon-play"/>
                        </div>
                        <div className="flex-fill fitting-video-text-box">
                            <h4 className="font-bold font-color-title text-nowrap mb-0">{_t('complaint.sizeguide.header')}</h4>
                            <p className="font-size-small font-color-middle-grey text-nowrap mb-0">{_t('complain.sizeguide.text')}</p>
                            <p className="font-bold font-size-small font-color-primary text-nowrap">{_t('complaint.sizeguide.cta')}</p>
                        </div>
                    </div>
                </div>
            );
        },
    };
}
