import DialogPreviewBody from './DialogPreviewBody';
import DialogUploadBody from './DialogUploadBody';

let DialogBody = function () {
    return {
        view: function (vnode) {
            let model = vnode.attrs.model;

            return (
                <div className='photo-upload'>
                    {model.isPreviewMode() ? <DialogPreviewBody model={vnode.attrs.model} /> : <DialogUploadBody model={vnode.attrs.model} />}
                </div>
            );
        },
    };
};

export default DialogBody;
