import OptionSelector from '../../../../../widgets/OptionSelector';
import InputLabel from '../../../../../widgets/InputLabel';
import HiddenSubmitButton from '../../../../../widgets/HiddenSubmitButton';

export default function () {
    return {
        oncreate: function (vnode) {
            let formModel = vnode.attrs.order.getDeleteOrderFormModel();
            formModel.addSubmitListener($(vnode.dom).find('form[name="' + formModel.getOrderDeleteModel().getType() + '"]')[0]);
        },
        view: function (vnode) {
            let formModel = vnode.attrs.order.getDeleteOrderFormModel();
            let model = formModel.getOrderDeleteModel();

            return (
                <div className="form-container">
                    <form name={model.getType()} novalidate>
                        <div>
                            <InputLabel label={_t('deleteorder.label.sizereasons')}/>
                            <OptionSelector options={model.getReasons()} selected={model.getReason()} onChange={model.setReason} focusClass={formModel.getOrderDeleteClass()} />
                        </div>
                        <HiddenSubmitButton />
                    </form>
                </div>
            );
        },
    };
}
