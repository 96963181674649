import ShopDeliveryType from './ShopDeliveryType';

let OrderInvoiceTypeTooltipMap = {};

OrderInvoiceTypeTooltipMap[ShopDeliveryType.HOME] = 'delivery.tooltip.home';
OrderInvoiceTypeTooltipMap[ShopDeliveryType.SCHOOL] = 'delivery.tooltip.school';
OrderInvoiceTypeTooltipMap[ShopDeliveryType.HOME_CONFIRMED] = 'delivery.tooltip.homeconfirmed';
OrderInvoiceTypeTooltipMap[ShopDeliveryType.SCHOOL_CONFIRMED] = 'delivery.tooltip.schoolconfirmed';
OrderInvoiceTypeTooltipMap[ShopDeliveryType.HOME_CONCEALED] = 'delivery.tooltip.homeconcealed';
OrderInvoiceTypeTooltipMap[ShopDeliveryType.SCHOOL_CONCEALED] = 'delivery.tooltip.schoolconcealed';
OrderInvoiceTypeTooltipMap[ShopDeliveryType.FITTING] = 'delivery.tooltip.fittingexpected';

export default OrderInvoiceTypeTooltipMap;
