import Form from './login/Form';
import FormMobile from './login/FormMobile';
import Bootstrap from '../../extentions/Bootstrap';
import DialogLostLoginRequest from './login/DialogLostLoginRequest';
import DialogLostLoginResponse from './login/DialogLostLoginResponse';
import LostLoginFormModel from '../../models/form/LostLoginForm';
import LoginFormModel from '../../models/form/LoginForm';
import DialogDeletedOrder from './login/DialogDeletedOrder';
import DialogOrderNotFound from './login/DialogOrderNotFound';

export default function() {
    let lostLoginRequestDialog;
    let lostLoginResponseDialog;
    let orderNotFoundDialog;

    let formLostLogin = new LostLoginFormModel();
    let form = new LoginFormModel();

    function submitLostLogin() {
        $('form[name="' + formLostLogin.getFormName() + '"]').find(':submit').click();

        if (formLostLogin.isSubmittedFormValid()) {
            lostLoginRequestDialog.hide();
            Bootstrap.blockUI();
            formLostLogin.submit().then(function (response) {
                Bootstrap.unblockUI();
                lostLoginResponseDialog.show(response.status);
            });
        }
    }

    return {
        view: function(vnode) {
            return (
                <main className="row login flex-fill">
                    {vnode.attrs.order && vnode.attrs.order.isDeletedDialogue() ? <DialogDeletedOrder order={vnode.attrs.order} oninit={function (vnode) {
                        vnode.state.show(vnode.attrs.order);
                    }}/> : ''}
                    <DialogLostLoginRequest form={formLostLogin} onSubmit={submitLostLogin} oncreate={function (vnode) {
                        lostLoginRequestDialog = vnode.state;
                    }}/>
                    <DialogLostLoginResponse oncreate={function (vnode) {
                        lostLoginResponseDialog = vnode.state;
                    }}/>
                    <DialogOrderNotFound oncreate={function (vnode) {
                        orderNotFoundDialog = vnode.state;
                    }}/>
                    {
                        Bootstrap.isMobileView() ?
                            (<FormMobile order={vnode.attrs.order} form={form} showOrderNotFoundDialog={(message) => orderNotFoundDialog.show(message)} lostLoginRequestDialog={() => lostLoginRequestDialog.show()}/>)
                            : (<Form order={vnode.attrs.order} form={form} showOrderNotFoundDialog={(message) => orderNotFoundDialog.show(message)} lostLoginRequestDialog={() => lostLoginRequestDialog.show()}/>)
                    }
                </main>
            );
        },
    };
}
