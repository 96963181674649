import OrderItemLabel from './OrderItemLabel';
import Bootstrap from '../../../extentions/Bootstrap';

export default function() {
    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            let invoice = order.getInvoice();
            let InvoiceComponent = invoice.getComponent();
            let InvoiceComponentButton = invoice.getUrl() ? invoice.getComponentButton() : null;
            let isInvoiceComponentButton = !! InvoiceComponentButton;
            return (
                <div className="row ml-0 mt-1 item-status cart-style">
                    <div className={(isInvoiceComponentButton ? 'col-sm-8 col-12' : 'col-12') + ' px-2'}>
                        <div className={'d-flex h-100 w-100 align-items-center' + (Bootstrap.isMobileView() ? (isInvoiceComponentButton ? ' pt-2' : ' py-2') : '')}>
                            <div className="icon icon-payment" />
                            <div className="flex-fill pl-1 line-height-middle">
                                <OrderItemLabel label={_t('invoice.label.info')}/>
                                <InvoiceComponent order={order} />
                            </div>
                        </div>
                    </div>
                    <div className={(isInvoiceComponentButton ? 'col-sm-4 col-12' : 'd-none') + ' px-2'}>
                        <div className="d-flex h-100 align-items-center justify-content-end">
                            {isInvoiceComponentButton ? <InvoiceComponentButton order={order} /> : '' }
                        </div>
                    </div>
                </div>
            );
        },
    };
}
