import DialogSelectImage from './DialogCustomPhoto';
import DialogSelectImageError from './DialogCustomPhotoError';
import Bootstrap from "../../../../../extentions/Bootstrap";

let ButtonCustomPhoto = function () {
    let imageDialog;
    let imageErrorDialog;

    return {
        oncreate: function (vnode) {
            Bootstrap.displayTooltip(vnode.dom);
        },
        onbeforeremove: function (vnode) {
            Bootstrap.hideTooltip(vnode.dom);
        },
        view: function (vnode) {
            let model = vnode.attrs.model;
            let order = vnode.attrs.order;

            let previewHtml = model.getUrl() ? '<div class="preview-container"><img class="h-100 w-100" src="' + model.getUrl() + '" /></div>' : '';

            let btnIcon = order.isLocked() && model.getUrl() ? 'icon-eye' : 'icon-camera';
            let btnClass = order.isLocked() && model.getUrl() ? 'btn-secondary-abc' : (model.getUrl() ? 'border-active btn-secondary-abc' : 'btn-secondary-abc');

            let btnOnclick = function (e) {
                e.preventDefault();
                e.stopPropagation();
                $("div[role='tooltip']").remove();
                imageDialog.show();
            };

            return (
                <div>
                    <div className={'button-container-photo ' + (model.getUrl() ? 'bs-tooltip' : '')} data-toggle="tooltip" data-placement="top"
                         data-container="body"
                         data-original-title={previewHtml}>
                        <button
                            className={'btn w-100 h-100 d-flex align-items-center justify-content-center ' + btnClass}
                            onclick={btnOnclick}>
                            <span className={'d-block ' + btnIcon}/>
                        </button>
                        <DialogSelectImage model={model} order={order} showImageErrorDialog={() => {
                            imageErrorDialog.show();
                        }} oncreate={function (vnode) {
                            imageDialog = vnode.state;
                        }}/>
                        <DialogSelectImageError model={model} oncreate={function (vnode) {
                            imageErrorDialog = vnode.state;
                        }}/>
                    </div>
                </div>
            );
        },
    };
};

export default ButtonCustomPhoto;
