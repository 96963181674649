export default function() {
    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            return (
                <a onclick={() => order.unload()} className="btn btn-secondary-abc text-uppercase font-size-small h-100 d-flex align-items-center justify-content-center">
                    <span className="d-inline-block icon-logout icon-margin-right"/>
                    <span className="d-none d-sm-none d-md-inline-block">{_t('header.cta.logout')}</span>
                </a>
            );
        },
    };
}
