import HomeNav from './header/HomeNav';
import CSNav from './header/CSNav';
import ShopNav from './header/ShopNav';
import LogoutButton from './header/Logout';
import Logo from './header/Logo';

export default function() {
    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            return (
                <header className="row fixed-top">
                    <div className="w-100 d-flex justify-content-between align-items-center px-1">
                        <div className="col-lg-4 col-md-3">
                            <HomeNav />
                        </div>
                        <div className="col-lg-4 col-md-3">
                            <Logo />
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex justify-content-end align-items-center">
                            <CSNav />
                            <div className="mr-3 button-container">
                                {order.isLoaded() && ! order.isDeleted() ? (<LogoutButton order={order}/>) : (<ShopNav />)}
                            </div>
                        </div>
                    </div>
                </header>
            );
        },
    };
}
