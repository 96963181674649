import OptionSelector from '../../../../../widgets/OptionSelector';
import ImageFileSelector from '../../../../../widgets/ImageFileSelector';
import Textarea from '../../../../../widgets/Textarea';
import InputLabel from '../../../../../widgets/InputLabel';
import HiddenSubmitButton from '../../../../../widgets/HiddenSubmitButton';

export default function () {

    return {
        oncreate: function (vnode) {
            let formModel = vnode.attrs.order.getComplaintFormModel();
            formModel.addSubmitListener($(vnode.dom).find('form[name="' + vnode.attrs.categoryModel.getType() + '"]')[0]);
        },
        view: function (vnode) {
            let order = vnode.attrs.order;
            let model = vnode.attrs.categoryModel;

            let fileUploadInputs = [];
            let fileUploadInputsCount = model.getPhotos().length >= 2 ? 3 : model.getPhotos().length + 1;

            for (let i = 0; i < fileUploadInputsCount; i++) {
                let fileName = typeof model.getPhoto(i) !== 'undefined' ? model.getPhoto(i).name : null;
                fileUploadInputs.push(
                    <div className="row">
                        <div className={i === 0 || i > 0 && typeof model.getPhoto(i) === 'undefined' ? 'col-12' : 'col-10'}>
                            <ImageFileSelector required={i === 0} onChange={model.setPhoto} index={i} file={model.getPhoto(i)} fileName={fileName} maxFileSize={model.getMaxPhotoSize()} acceptedTypes={model.getPhotoFileTypes()}/>
                        </div>
                        <div className={'modal-close ' + (i > 0 && typeof model.getPhoto(i) !== 'undefined' ? ' col-2 d-flex justify-content-end pt-0' : 'd-none')}>
                            <a className="d-flex justify-content-center align-items-center border-0 rounded"
                                onclick={() => {
                                    model.removePhoto(i);
                                    setTimeout(function() {
                                        m.redraw();
                                    }, 50);
                                }}>
                                <span className="d-block icon-close"/>
                            </a>
                        </div>
                    </div>,
                );
            }
            
            return (
                <div className="form-container">
                    <form name={model.getType()} novalidate>
                        <div>
                            <InputLabel label={_t('complaint.label.product')}/>
                            <OptionSelector options={model.getProducts(order)} selected={model.getProduct()} onChange={model.setProduct} focusClass={vnode.attrs.order.getComplaintFormModel().getComplaintClass()}/>
                        </div>
                        <div className="pt-2">
                            <InputLabel label={_t('complaint.label.defectivereasons')}/>
                            <OptionSelector options={model.getReasons()} selected={model.getReason()} onChange={model.setReason} focusClass={vnode.attrs.order.getComplaintFormModel().getComplaintClass()}/>
                        </div>
                        <div className="pt-2">
                            <InputLabel label={_t('complaint.label.defectivedescription')}/>
                            <Textarea onChange={model.setDescription} value={model.getDescription()} required="true" placeholder={_t('complaint.wrongsize.textarea.placeholder').children} />
                        </div>
                        <div className="pt-2">
                            <InputLabel label={_t('complaint.label.photo')}/>
                            {fileUploadInputs}
                        </div>
                        <HiddenSubmitButton />
                    </form>
                </div>
            );
        },
    };
}
