import FormContainer from './FormContainer';
import Bootstrap from '../../../../../extentions/Bootstrap';

export default function() {
    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            let formModel = order.getComplaintFormModel();
            return (
                <div className={Bootstrap.isTabletView() ? 'p-2' : 'p-4'}>
                    {formModel.getActiveCategory(order) ? (<FormContainer order={order} auxModal={vnode.attrs.auxModal}/>) : '' }
                </div>
            );
        },
    };
}
