import ComplaintCategoryTypeTitleMap from './ComplaintCategoryTypeTitleMap';
import ComplaintCategoryTypeComponentMap from './ComplaintCategoryTypeComponentMap';

let ComplaintDefectiveProductModel = function (type, setupData) {

    let product;
    let reason = 0;
    let description;
    let photos = [];
    let self = this;

    this.getType = function () {
        return type;
    };

    this.getTitle = function () {
        return ComplaintCategoryTypeTitleMap[type];
    };

    this.getReasons = function () {
        return setupData.reasons;
    };

    this.getProducts = function (order) {
        let products = {};
        _.each(order.getRows(), function (row) {
            if (row.isVisible() && setupData.excludeProducts.indexOf(row.getProduct().getArticleNumber()) === -1) {
                products[row.getId()] = row.hasProductVariants() ? row.getProductName() + ' [' + row.getProductVariants() + ']' : row.getProductName();
            }
        });
        return products;
    };

    this.getComponent = function (order) {
        return m(ComplaintCategoryTypeComponentMap[type], {order: order, categoryModel: this});
    };

    this.isApplicable = function (order) {
        return Object.keys(this.getProducts(order)).length > 0;
    };

    this.getProduct = function () {
        return product;
    };

    this.setProduct = function (value) {
        product = value;
    };

    this.getReason = function () {
        return reason;
    };

    this.setReason = function (value) {
        reason = parseInt(value);
    };

    this.getDescription = function () {
        return description;
    };

    this.setDescription = function (value) {
        description = value;
    };

    this.getMaxPhotoSize = function () {
        return window.config.photoUpload.maxUploadSize;
    };

    this.getPhotoFileTypes = function () {
        return window.config.photoUpload.allowedMimeTypes;
    };

    this.getPhotos = function () {
        return photos;
    };

    this.getPhoto = function (index) {
        return photos[index];
    };

    this.removePhoto = function (index) {
        photos.splice(index, 1);
        photos = photos.map(function(photo) {
            return photo;
        });
    };

    this.setPhoto = function (index, value) {
        if (value === null) {
            self.removePhoto(index);
        } else {
            photos[index] = value;
        }
    };

    this.isReady = function () {
        return !! description && !! photos[0];
    };

    this.getPath = function () {
        return '/complaint/defective';
    };

    this.getFormData = function() {
        let data = new FormData();
        for (let i = 0; i < photos.length; i++) {
            data.append('files[]', photos[i]);
        }
        data.append('form', JSON.stringify(this.toJSON().form));
        return data;
    };

    this.toJSON = function () {
        return {
            files: photos,
            form: {
                product: product,
                reason: reason,
                description: description,
            },
        };
    };
};

export default ComplaintDefectiveProductModel;
