import ModalDialog from '../../../../../widgets/ModalDialog';

export default function () {
    let ContainerDialog;
    let closeCallback;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (callback) {
            closeCallback = callback;
            ContainerDialog.show(this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }
            let iconClass = 'icon-info';
            let header = _t('postpone.success.header');
            let body = <span className="font-color-title font-size-large font-bold">{_t('postpone.success.text')}</span>;
            let cancelContent = <span>{ _t('message_0052') }</span>;
            let onCancel = function () {
                ContainerDialog.hide();
                closeCallback();
            };
            return (
                <ContainerDialog canClose={true} outsideClickClose={true} onCancel={vnode.attrs.onCancel}
                    header={header} body={body} iconClass={iconClass} cancelContent={cancelContent} cancelAction={() => onCancel()}/>
            );
        },
    };
}
