import OrderItemLabel from './OrderItemLabel';
import Tooltip from '../../../widgets/Tooltip';
import Bootstrap from '../../../extentions/Bootstrap';
import DateHelper from '../../../extentions/DateHelper';

export default function() {
    return {
        oncreate: function (vnode) {
            Bootstrap.displayTooltip(vnode.dom);
        },
        onbeforeremove: function (vnode) {
            Bootstrap.hideTooltip(vnode.dom);
        },
        view: function(vnode) {
            let delivery = vnode.attrs.order.getShop().getDelivery();
            let dateString = delivery.isDateFittingShop() ? delivery.getDateFittingShop() : delivery.getDate();
            let date = dateString ? DateHelper.getDateInSeFormat(new Date(dateString)) : _t('message_0033').children;
            return (
                <div className="row ml-0 mt-1 item-status cart-style">
                    <div className="col-12 px-2">
                        <div className={'d-flex h-100 w-100 align-items-center' + (Bootstrap.isMobileView() ? ' py-2' : '')}>
                            <div className="icon icon-delivery" />
                            <div className="flex-fill pl-1 line-height-middle">
                                <OrderItemLabel label={_t('delivery.label.info')}/>
                                <p className="m-0 font-color-title font-bold font-size-big d-flex align-items-center">
                                    <span className="d-inline-block">{delivery.getTextTitle({date: date})}</span>
                                    <Tooltip message={delivery.getTooltipTitle().children}/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    };
}
