import CustomPhoto from './photo/CustomPhoto';
import TextPrintEl from './TextPrintEl';
import TextPrintMobileEl from './TextPrintMobileEl';

export default function() {
    return {
        view: function(vnode) {
            let row = vnode.attrs.row;
            return (
                <tr className={vnode.attrs.index === 0 ? 'first-variant' : ''}>
                    <td className="w-65">
                        <div>
                            <span className="col-12 d-block font-color-title font-bold text-uppercase">{row.getProductName()}</span>
                            {row.hasProductVariants()
                                ? <span className="col-12 d-block font-size-small">{row.getProductVariants()}</span>
                                : ''
                            }
                            <TextPrintMobileEl row={row}/>
                        </div>

                    </td>
                    <td className="w-25">
                        <CustomPhoto row={row} order={vnode.attrs.order} />
                        <TextPrintEl row={row}/>
                    </td>
                    <td className="text-right w-10">
                        <span className="col-12 d-block font-color-title font-bold price-column">{row.getPrice()}</span>
                        {row.hasSavings()
                            ? <span className="col-12 d-block font-size-small"><del>{row.getListPrice()}</del></span>
                            : ''
                        }
                    </td>
                </tr>
            );
        },
    };
}
