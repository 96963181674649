import Bootstrap from '../extentions/Bootstrap';

let ModalDialog = function () {

    let instance;
    let visible = false;
    let closed = false;
    let closeModal = function (attrs) {
        if (attrs.cancelAction) {
            attrs.cancelAction();
        } else {
            cancel(attrs.onCancel);
        }
    };

    function close() {
        visible = false;
        instance && instance.modal('hide');
        instance = undefined;
    }

    function cancel(onCancel) {
        onCancel && onCancel();
        close();
    }

    return {
        show: function (item) {
            visible = item || true;
        },
        cancel: function (onCancel) {
            cancel(onCancel);
        },
        isVisible: function() {
            return !! visible;
        },
        hide: function () {
            close();
        },
        setClosed: function (status) {
            closed = status;
        },
        onremove: function () {
            close();
        },
        oncreate: function (vnode) {
            let element = vnode.dom;
            let el = $(element);
            el.on('shown.bs.modal', function () {
                if (vnode.attrs.nested) {
                    document.body.classList.add('modal-open');
                }
                vnode.attrs.focus && setTimeout(function() {$(vnode.attrs.focus).focus();}, 10);

                element.onkeydown = function (e) {
                    if (27 === e.keyCode) {
                        e.stopImmediatePropagation();
                        vnode.attrs.canCloseAction ? vnode.attrs.canCloseAction() : closeModal(vnode.attrs);
                        m.redraw();
                    }
                };
            });

            vnode.attrs.hideScroll && vnode.attrs.hideScroll();
            vnode.attrs.onCreate && vnode.attrs.onCreate();

            if (! instance) {
                let canClose = (typeof vnode.attrs.canClose === 'undefined' ? true : !! vnode.attrs.canClose);
                instance = el.modal({
                    keyboard: canClose,
                    backdrop: vnode.attrs.outsideClickClose === true ? true : 'static',
                });
                typeof vnode.attrs.onShow === 'function' && vnode.attrs.onShow();
                el.on('hidden.bs.modal', function(){
                    if (visible) {
                        visible = false;
                        m.redraw();
                    }
                    if (vnode.attrs.nested && closed) {
                        document.body.classList.add('modal-open');
                    }
                });

                if (vnode.attrs.outsideClickClose === true) {
                    element.onclick = function (e) {
                        if (! $('.modal-content', element)[0].contains(e.target)) {
                            setTimeout(function(){
                                vnode.attrs.canCloseAction ? vnode.attrs.canCloseAction() : (vnode.attrs.cancelAction ? vnode.attrs.cancelAction() : cancel(vnode.attrs.onCancel));
                                typeof vnode.attrs.outsideClickCloseCallback === 'function' && vnode.attrs.outsideClickCloseCallback();
                                m.redraw();
                            }, 500);
                        }
                    };
                }
            }
        },
        view: function (vnode) {
            let canClose = (typeof vnode.attrs.canClose === 'undefined' ? true : vnode.attrs.canClose);
            let isCentralAligned = typeof vnode.attrs.isCentralAligned === 'undefined' ? true : vnode.attrs.isCentralAligned();

            let paddingDesktop = vnode.attrs.paddingDesktopCustom ? vnode.attrs.paddingDesktopCustom : 'p-4';
            let paddingMobile = vnode.attrs.paddingMobileCustom ? vnode.attrs.paddingMobileCustom : 'p-2';

            return (
                <div className={'modal-notification modal px-0 fade show '  + (isCentralAligned ? 'd-flex align-items-center' : '')} aria-hidden="true" role="dialog" tabindex="-1">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className='header-element'>
                                <div className={(Bootstrap.isTabletView() ? 'px-2' : 'px-4') + ' h-100 d-flex justify-content-between'}>
                                    <h3 className="m-0 align-self-center"> {vnode.attrs.header}</h3>
                                    {
                                        canClose ?
                                            <div className="modal-close">
                                                <a className="d-flex justify-content-center align-items-center border-0 rounded" aria-label="Close" data-dismiss="modal" onclick={() => {
                                                    vnode.attrs.canCloseAction ? vnode.attrs.canCloseAction() : (vnode.attrs.cancelAction ? vnode.attrs.cancelAction() : cancel(vnode.attrs.onCancel));
                                                }}>
                                                    <span className="d-block icon-close"/>
                                                </a>
                                            </div>
                                            : ''
                                    }
                                </div>
                            </div>
                            <div className="modal-container">
                                <div className="modal-body p-0">
                                    <div className={(Bootstrap.isTabletView() ? paddingMobile : paddingDesktop) + ' d-flex'}>
                                        {vnode.attrs.iconClass ? <div className={vnode.attrs.iconClass} /> : ''}
                                        <div className={vnode.attrs.iconClass ? 'flex-fill pl-1 icon-modal-p-top' : 'w-100'}>
                                            {vnode.attrs.body}
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-element ">
                                    <div className={(Bootstrap.isTabletView() ? 'pb-2' : 'pb-4') + ' d-flex align-items-center justify-content-center'}>
                                        <div className={(Bootstrap.isTabletView() ? 'px-2' : 'px-4') + ' w-100 text-uppercase'}>
                                            {
                                                vnode.attrs.acceptAction ?
                                                    <div className={(vnode.attrs.cancelAction ? 'button-container-width' : 'w-100') + ' d-inline-block button-container'}>
                                                        <a className="d-flex btn btn-secondary-abc w-100 h-100 link-color align-items-center justify-content-center" onclick={() => {vnode.attrs.acceptAction();}}>
                                                            {vnode.attrs.acceptContent}
                                                        </a>
                                                    </div>
                                                    : ''
                                            }
                                            {
                                                vnode.attrs.cancelContent ?
                                                    <div className={(vnode.attrs.acceptAction ? 'button-container-width' : 'w-100') + ' d-inline-block button-container float-right'}>
                                                        <a className="d-flex btn btn-info-abc w-100 h-100 align-items-center justify-content-center font-color-white" onclick={() => {closeModal(vnode.attrs);}}>
                                                            {vnode.attrs.cancelContent}
                                                        </a>
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    };
};

export default ModalDialog;
