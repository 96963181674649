import OrderStatusType from './OrderStatusType';

let OrderStatusTypeTooltipMap = {};

OrderStatusTypeTooltipMap[OrderStatusType.LOCKED] = 'orderstatus.tooltip.unlocked';
OrderStatusTypeTooltipMap[OrderStatusType.UNLOCKED_INVOICED] = 'orderstatus.tooltip.unlocked';
OrderStatusTypeTooltipMap[OrderStatusType.LOCKED_PURCHASED] = 'orderstatus.tooltip.locked';
OrderStatusTypeTooltipMap[OrderStatusType.LOCKED_DELIVERED] = 'orderstatus.tooltip.delivered';
OrderStatusTypeTooltipMap[OrderStatusType.UNLOCKED] = 'orderstatus.tooltip.unlocked';

export default OrderStatusTypeTooltipMap;
