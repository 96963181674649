import CustomPhoto from './photo/CustomPhoto';

export default function() {
    return {
        view: function(vnode) {
            let row = vnode.attrs.row;
            return (
                <tr>
                    <td className="w-65 border-0">
                        <div className="d-flex justify-content-between">
                            <div>
                                <span className="col-12 d-block font-color-title font-bold font-size-big">{row.getProductName()}</span>
                                {row.hasProductVariants()
                                    ? <span className="col-12 d-block">{row.getProductVariants()}</span>
                                    : ''
                                }
                            </div>
                        </div>
                    </td>
                    <td className="w-25 border-0">
                        <CustomPhoto row={row} order={vnode.attrs.order} />
                    </td>
                    <td className="text-right w-10 border-0">
                        <span className="col-12 d-block font-bold font-color-title price-column">{row.getPrice() - (row.isTherePriceForVar2VariantSubtypeHats() ? row.getVariantPriceForDimension('VAR_2') : 0)}</span>
                        {row.hasSavings()
                            ? <span className="col-12 d-block text-secondary font-size-small"><del>{row.getListPrice() - (row.isTherePriceForVar2VariantSubtypeHats() ? row.getVariantPriceForDimension('VAR_2') : 0)}</del></span>
                            : ''
                        }
                    </td>
                </tr>
            );
        },
    };
}
