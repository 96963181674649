import ButtonCustomPhoto from './ButtonCustomPhoto';

export default function() {
    return {
        view: function(vnode) {
            let row = vnode.attrs.row;
            return (
                <div>
                    {row.hasCustomPhoto()
                        ? <ButtonCustomPhoto model={row.getCustomPhotoModel()} order={vnode.attrs.order}/>
                        : ''
                    }
                </div>
            );
        },
    };
}
