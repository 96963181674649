import ModalDialog from '../../../../../widgets/ModalDialog';

export default function () {
    let ContainerDialog;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }
            let header = _t('dialog.header.sizeguide');
            let body = <div className="hat-measure-iframe"><iframe src={vnode.attrs.target} allowFullScreen="" width={vnode.attrs.width} height={vnode.attrs.height} allow="autoplay; encrypted-media" frameBorder="0"/></div>;
            let cancelContent = <span>{_t('message_0052')}</span>;

            let onCancel = function () {
                ContainerDialog.setClosed(true);
                vnode.attrs.onClose && vnode.attrs.onClose();
            };
            return (
                <ContainerDialog nested={true} hideScroll={vnode.attrs.hideScroll} canClose={true} outsideClickClose={true} onCancel={onCancel}
                    header={header} body={body} cancelContent={cancelContent}/>
            );
        },
    };
}
