import Header from './Header';
import HeaderMobile from './HeaderMobile';
import HeaderBlank from './HeaderBlank';
import Footer from './Footer';
import Bootstrap from '../extentions/Bootstrap';

export default function() {

    let content = function (page, order) {
        let attrs = {order: order};
        return [
            m(HeaderBlank, attrs),
            m(Bootstrap.isTabletView() ? HeaderMobile : Header, attrs),
            m(page, attrs),
            m(Footer, attrs),
        ];
    };

    return {
        view(vnode) {
            let page = vnode.attrs.page;
            let order = vnode.attrs.order;
            return (
                <div className={(order.isLoaded() && ! order.isDeleted() ? '' : 'login-container-bg')}>
                    <wrapper className={'min-vh-100 d-flex flex-column container-fluid ' +
                    (order.isLoaded() && ! order.isDeleted() ? 'order-container' : 'login-container')}>
                        {content(page, order)}
                    </wrapper>
                </div>
            );
        },
    };
}
