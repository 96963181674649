import ProductModel from '../product/Product';
import CustomPhotoModel from './photo/CustomPhoto';

let OrderRowModel = function (Translator, rowData, order) {

    let product = new ProductModel(Translator, rowData.product, rowData.dimensions);
    let customPhoto;

    let self = this;

    this.getId = function () {
        return rowData.idOrderRow;
    };

    this.getParentOrderRow = function () {
        return rowData.parentOrderRow;
    };

    this.getCustomPhotoModel = function () {
        return customPhoto;
    };

    this.hasCustomPhoto = function () {
        return rowData.hasCustomPhoto;
    };

    this.getCustomPhotoUrl = function () {
        return customPhoto.getUrl();
    };

    this.getPrice = function () {
        return rowData.price;
    };

    this.getListPrice = function () {
        return rowData.listPrice;
    };

    this.getSavings = function() {
        return rowData.listPrice - rowData.price;
    };

    this.hasSavings = function () {
        return rowData.listPrice > 0 && this.getSavings() > 0;
    };

    this.hasTextPrint = function () {
        return product.hasTextPrint();
    };

    this.getTextPrint = function () {
        return rowData.textPrint;
    };

    this.getProduct = function () {
        return product;
    };

    this.getProductName = function () {
        return product.getName();
    };

    this.isVisible = function () {
        return product.isVisible();
    };

    this.getProductType = function () {
        return product.getSubtype();
    };

    this.hasProductVariants = function () {
        return product.hasVariants();
    };

    this.getProductVariants = function () {
        let dimensions = product.getDimensions();
        let values = [];
        for (const [key, dimension] of Object.entries(dimensions)) {
            if (product.getSubtype() !== 'hats'
                || key !== 'VAR_2' && product.getSubtype() === 'hats'
                || key === 'VAR_2' && product.getSubtype() === 'hats' && (self.getVariantPriceForDimension(key) === 0 )
            ) {
                values.push(dimension.getVariant().getValue());
                values.push(', ');
            }
        }

        values.pop();
        return values.join('');
    };

    if (rowData.hasCustomPhoto) {
        customPhoto = new CustomPhotoModel(product.getArticleNumber(), rowData.customPhotoUrl, order);
    }

    this.getVariantPriceForDimension = function (dimension) {
        return rowData.variantPrices && rowData.variantPrices[dimension] ? rowData.variantPrices[dimension].price : 0;
    };

    this.isTherePriceForVar2VariantSubtypeHats = function () {
        return product.getSubtype() === 'hats' && (self.getVariantPriceForDimension('VAR_2') > 0 );
    };

    this.getProductVariantValueForVar2 = function () {
        let dimensions = product.getDimensions();
        let value = null;
        for (const [key, dimension] of Object.entries(dimensions)) {
            if (product.getSubtype() === 'hats' && key === 'VAR_2' && (self.getVariantPriceForDimension(key) > 0 )) {
                value = dimension.getVariant().getValue();
                break;
            }
        }
        return value;
    };

    this.getProductVariantNameForVar2 = function () {
        let dimensions = product.getDimensions();
        let name = null;
        for (const [key, dimension] of Object.entries(dimensions)) {
            if (product.getSubtype() === 'hats' && key === 'VAR_2' && (self.getVariantPriceForDimension(key) > 0 )) {
                name = dimension.getName();
                break;
            }
        }
        return name;
    };

};

export default OrderRowModel;
