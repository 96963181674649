export default function() {
    return {
        view: function() {
            return (
                <a className="btn btn-block btn-info-abc text-uppercase font-size-small h-100 d-flex align-items-center justify-content-center" href={window.config.companyShopLink} target="_blank">
                    <span className="d-inline-block icon-design icon-margin-right" />
                    <span className="d-inline-block">{_t('header.cta.shop')}</span>
                </a>
            );
        },
    };
}
