import ModalDialog from '../../../../../widgets/ModalDialog';

export default function () {
    let ContainerDialog;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }
            let iconClass = 'icon-error';

            let header = _t('postpone.error.header');
            let body = <span className="font-color-title font-size-large font-bold">{_t('postpone.error.text')}</span>;
            let cancelContent = <span>{ _t('message_0052') }</span>;
            return (
                <ContainerDialog canClose={true} outsideClickClose={true} onCancel={vnode.attrs.onCancel}
                    header={header} body={body} iconClass={iconClass} cancelContent={cancelContent} cancelAction={() => ContainerDialog.hide()}/>
            );
        },
    };
}
