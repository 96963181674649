import UuidGenerator from '../../extentions/UuidGenerator';

let LoginFormModel = function () {
    let formName = UuidGenerator.uuidv4();
    let submittedFormValid;


    this.getFormName = function () {
        return formName;
    };

    this.isSubmittedFormValid = function () {
        return submittedFormValid;
    };

    this.addSubmitListener = function (form) {
        form.addEventListener('submit', function(event) {
            event.preventDefault();
            event.stopPropagation();

            submittedFormValid = form.checkValidity();
            form.classList.add('was-validated');
        }, false);
    };
};

export default LoginFormModel;
