import ShopDeliveryType from './ShopDeliveryType';

let OrderInvoiceTypeTextMap = {};

OrderInvoiceTypeTextMap[ShopDeliveryType.HOME] = 'delivery.text.home';
OrderInvoiceTypeTextMap[ShopDeliveryType.SCHOOL] = 'delivery.text.school';
OrderInvoiceTypeTextMap[ShopDeliveryType.HOME_CONFIRMED] = 'delivery.text.homeconfirmed';
OrderInvoiceTypeTextMap[ShopDeliveryType.SCHOOL_CONFIRMED] = 'delivery.text.schoolconfirmed'
OrderInvoiceTypeTextMap[ShopDeliveryType.HOME_CONCEALED] = 'delivery.text.homeconcealed';
OrderInvoiceTypeTextMap[ShopDeliveryType.SCHOOL_CONCEALED] = 'delivery.text.schoolconcealed';
OrderInvoiceTypeTextMap[ShopDeliveryType.FITTING] = 'delivery.text.fittingexpected';

export default OrderInvoiceTypeTextMap;
