import OrderDetails from './order/OrderDetails';
import OrderDuplicate from './order/OrderDuplicate';
import CustomerInfo from './order/CustomerInformation';
import DeliveryInfo from './order/DeliveryInformation';
import InvoiceInfo from './order/InvoiceInformation';
import OrderStatus from './order/OrderStatus';
import Hat3D from './order/Hat3D';
import OrderChangeButton from './order/button/OrderChangeButton';
import OrderDeleteButton from './order/button/OrderDeleteButton';
import Bootstrap from '../../extentions/Bootstrap';

export default function() {
    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            return (
                <div className="row order">
                    <div className="w-100 logo-container">
                        <div className="w-100 d-flex align-items-center logo">
                            <div className="container-xl my-1">
                                <p className="text-uppercase font-bold">{_t('order.logo.number')} #{order.getUID()}</p>
                                <h1 className="font-color-white">{_t('order.logo.hi')}, {order.getCustomer().getFirstName()}</h1>
                            </div>
                        </div>
                    </div>
                    <div className={'container-xl' + (Bootstrap.isTabletView() ? '' : ' mb-2')}>
                        <div className="row mt-1">
                            {
                                !order.isNoHat() ?
                                    <div className="col-lg-6 col-md-12 mt-1 item-hat-container item-p-right">
                                        <Hat3D order={order}/>
                                    </div> :
                                    ''
                            }
                            <div className={(order.isNoHat() ? 'col-12' : 'col-lg-6 col-md-12 item-p-left') + ' mt-1 item-status-container'}>
                                <div className="container-fluid pl-0">
                                    <OrderStatus order={order} />
                                    <InvoiceInfo order={order} />
                                    <DeliveryInfo order={order} />
                                    <CustomerInfo customer={order.getCustomer()} />
                                </div>
                            </div>
                        </div>
                        {
                            order.getDuplicatedOrders().length > 0 ? <OrderDuplicate order={order} /> : ''
                        }
                        <OrderDetails order={order} />
                        <div className="d-flex justify-content-end mb-1">
                            <div className="button-order-p-right button-order-container"><OrderChangeButton order={order}/></div>
                            <div className="button-order-p-left button-order-container"><OrderDeleteButton order={order}/></div>
                        </div>
                    </div>
                </div>
            );
        },
    };
}
