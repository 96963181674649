import Bootstrap from '../../../../../extentions/Bootstrap';
import DeleteReason from '../category/DeleteReason';

export default function() {
    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            return (
                <div className={Bootstrap.isTabletView() ? 'p-2' : 'p-4'}>
                    <div>
                        <div>
                            <p>{_t('deleteorder.header.title')}</p>
                        </div>

                        <div className="pt-3 pb-1">
                            <h4 className="font-bold">{_t('deleteorder.header.form')}</h4>
                        </div>

                        <div className="tab-content">
                            <DeleteReason order={order}/>
                        </div>
                    </div>
                </div>
            );
        },
    };
}
