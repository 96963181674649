import ModalDialog from '../../../widgets/ModalDialog';
import SupportElement from './SupportElement';

export default function () {
    let ContainerDialog;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            ContainerDialog.show(item || this);
        },
        view: function (vnode) {
            let acceptAction = function () {
                vnode.attrs.order.unload();
                vnode.attrs.onCancel && vnode.attrs.onCancel();
                ContainerDialog.hide();
            };

            let cancelAction = function () {
                window.open(window.config.companyShopLink, '_blank');
            };

            if (! ContainerDialog.isVisible()) {
                return '';
            }

            let header = _t('message_0039');
            let body = <SupportElement text={_t('message_0040')}/>;
            let iconClass = 'icon-error';

            let cancelContent = <span className="d-flex align-items-center">
                <span className="d-inline-block icon-design icon-margin-right" />
                <span className="d-inline-block">{_t('header.cta.shop')}</span>
            </span>;

            let acceptContent = <span className="d-flex align-items-center">
                <span className="d-inline-block">{_t('order.deleted.tryagain')}</span>
                <span className="d-inline-block icon-retry icon-margin-left" />
            </span>;

            return (
                <ContainerDialog iconClass={iconClass} canClose={true} canCloseAction={acceptAction} outsideClickClose={true}
                    header={header} body={body} cancelContent={cancelContent} acceptAction={acceptAction} cancelAction={cancelAction} acceptContent={acceptContent} />
            );
        },
    };
}
