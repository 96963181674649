export default function() {
    return {
        view: function() {
            return (
                <div className="pr-3">
                    <a target="_blank" className="text-uppercase link-color" href={window.config.customerServiceLink}>{_t('header.cta.cs')}</a>
                </div>
            );
        },
    };
}
