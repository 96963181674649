import OrderInvoiceType from './OrderInvoiceType';
import OrderInvoiceTypeTooltipMap from './OrderInvoiceTypeTooltipMap';
import OrderInvoiceTypeButtonComponentMap from './OrderInvoiceTypeButtonComponentMap';
import OrderInvoiceTypeComponentMap from './OrderInvoiceTypeComponentMap';

let OrderInvoiceModel = function (Translator, setupData) {

    let type = OrderInvoiceType.NOT_INVOICED;
    let invoiceData;

    this.getComponent = function () {
        return OrderInvoiceTypeComponentMap[type];
    };

    this.getComponentButton = function () {
        return OrderInvoiceTypeButtonComponentMap[type];
    };

    this.getTooltipTitle = function (substitutions) {
        return Translator.translate(OrderInvoiceTypeTooltipMap[type], substitutions);
    };

    this.getTextTitle = function () {
        if (type === OrderInvoiceType.NOT_INVOICED) {
            return Translator.translate('order.text.notinvoiced');
        }
        if (type === OrderInvoiceType.UNPAID) {
            return Translator.translate(
                'order.text.unpaid',
                {status: Translator.translate('invoice.status.unpaid').children, date: this.getDueDate()},
            );
        }
        return Translator.translate('invoice.status.' + invoiceData.status.toLowerCase());
    };

    this.getStatus = function () {
        return Translator.translate('invoice.status.' + invoiceData.status.toLowerCase());
    };

    this.getDueDate = function () {
        return invoiceData.respiteDate ? invoiceData.respiteDate : invoiceData.dueDate;
    };

    this.getInvoiceStatus = function () {
        return invoiceData.status.toLowerCase();
    };

    this.getUrl = function () {
        return invoiceData.url;
    };

    this.initFromData = function (data) {
        invoiceData = data;
        if (invoiceData.invoiced) {
            type = OrderInvoiceType.INVOICED;
        }

        if (invoiceData.canPostpone && ! invoiceData.respiteDate) {
            type = OrderInvoiceType.UNPAID;
        }
    };

    this.initFromData(setupData);

};

export default OrderInvoiceModel;
