import InvalidFeedbackMessage from './InvalidFeedbackMessage';
import Bootstrap from '../extentions/Bootstrap';

export default function () {
    return {
        oncreate: function (vnode) {
            if (vnode.attrs.required) {
                $('textarea', $(vnode.dom)).attr('required', true);
            }
        },
        view: function (vnode) {
            return (
                <div className="form-group textarea-container">
                    <textarea placeholder={vnode.attrs.placeholder} className="form-control shadow-none" rows="4" oninput={(e) => vnode.attrs.onChange(e.target.value)}>
                        {vnode.attrs.value ? vnode.attrs.value : ''}
                    </textarea>
                    <InvalidFeedbackMessage message={_t('form.errormessage.empty')}/>
                </div>
            );
        },
    };
}
