export default function() {
    return {
        view: function() {
            return (
                <li className="nav-item border-top">
                    <a target="_blank" className="ml-2 py-1 nav-link text-uppercase link-color" href={window.config.companySiteLink}>{_t('header.cta.home')}</a>
                </li>
            );
        },
    };
}
