import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'bootstrap';

import Translator from './extentions/Translator';
import Cookies from './extentions/Cookies';

import OrderModel from './models/order/Order';
import Router from './models/Router';

import Debouncer from './extentions/Debouncer';

if (module.hot) {
    module.hot.accept();
}

if (process.env.NODE_ENV !== 'production') {
    console.log('Looks like we are in development mode!');
}

m.trans = new Translator(window.config.translations, window.config.languages);
m.trans.setLanguage(window.config.defaultLanguage);

window._t = m.trans.translate;

let resizeHandler = window.onresize;
window.onresize = new Debouncer(function(e) {
    typeof resizeHandler === 'function' && resizeHandler(e);
    m.redraw.sync();
}, 250);

window.addEventListener('orientationchange', function () {
    m.redraw.sync();
});

let router = new Router(new OrderModel(new Cookies(), m.trans, window.config.order));
router.navigate(document.getElementById(process.env.M_ID));
