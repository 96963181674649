let Debouncer = function (func, wait, immediate) {
    'use strict';

    wait = wait || 100;
    immediate = immediate || false;

    let context, args, timestamp, timeout, result;

    let now = Date.now || function() {
        return new Date().getTime();
    };

    function debounced() {
        context = this;
        args = arguments;
        timestamp = now();

        if (! timeout) {
            timeout = setTimeout(later, wait);
            if (immediate) {
                result = func.apply(context, args);
                context = args = null;
            }
        }
        return result;
    }

    function later() {
        let elapsed = now() - timestamp;

        if (elapsed < wait && elapsed > 0) {
            timeout = setTimeout(later, wait - elapsed);
        } else {
            timeout = null;
            if (! immediate) {
                result = func.apply(context, args);
                context = args = null;
            }
        }
    }

    return debounced;
};

export default Debouncer;
