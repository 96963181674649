import FlagIcon from './FlagIcon';
import OptionSelector from '../../widgets/OptionSelector';

export default function() {
    return {
        view: function() {
            let onChange = function (id) {
                m.trans.setLanguage(m.trans.getLanguages()[id]);
            };

            return (
                <OptionSelector options={m.trans.getLanguages()} selected={_.invert(m.trans.getLanguages())[m.trans.getLanguage()]} onChange={onChange} iconComponent={FlagIcon} optionPrefix="language.label."/>
            );
        },
    };
}
