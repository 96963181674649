import ShopDeliveryType from './ShopDeliveryType';
import OrderDeliveryTypeTextMap from './OrderDeliveryTypeTextMap';
import OrderDeliveryTypeTooltipMap from './OrderDeliveryTypeTooltipMap';

let ShopDeliveryModel = function (Translator, shopData) {
    let deliveryDate = shopData.deliveryDate ? new Date(shopData.deliveryDate.replace(/-/g, '/')).valueOf() : null;
    let deliveryDateVisiblity = null;
    if (deliveryDate && shopData.deliveryVisibilityDays) {
        deliveryDateVisiblity = new Date();
        deliveryDateVisiblity.setDate(deliveryDateVisiblity.getDate() + parseInt(shopData.deliveryVisibilityDays));
    }
    let deliveryDateFittingShop = shopData.deliveryDateFittingShop ? new Date(shopData.deliveryDateFittingShop.replace(/-/g, '/')).valueOf() : null;

    let type = ShopDeliveryType.HOME;

    this.getTooltipTitle = function (substitutions) {
        return Translator.translate(OrderDeliveryTypeTooltipMap[type], substitutions);
    };

    this.getTextTitle = function (substitutions) {
        return Translator.translate(OrderDeliveryTypeTextMap[type], substitutions);
    };

    this.getDate = function () {
        return deliveryDate;
    };

    this.getDateFittingShop = function () {
        return deliveryDateFittingShop;
    };

    this.isDateFittingShop = function () {
        return !! deliveryDateFittingShop;
    };

    if (this.isDateFittingShop()) {
        type = ShopDeliveryType.FITTING;
    } else if (! deliveryDate || deliveryDateVisiblity && (deliveryDateVisiblity < deliveryDate)) {
        if (shopData.deliverToHome === 'yes') {
            type = ShopDeliveryType.HOME_CONCEALED;
        } else {
            type = ShopDeliveryType.SCHOOL_CONCEALED;
        }
    } else {
        if (shopData.deliverToHome === 'yes') {
            type = (shopData.ordered ? ShopDeliveryType.HOME_CONFIRMED : ShopDeliveryType.HOME);
        } else {
            type = (shopData.ordered ? ShopDeliveryType.SCHOOL_CONFIRMED : ShopDeliveryType.SCHOOL);
        }
    }
};

export default ShopDeliveryModel;
