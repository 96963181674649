import UuidGenerator from '../extentions/UuidGenerator';
import InvalidFeedbackMessage from './InvalidFeedbackMessage';

export default function () {
    let sizeError = null;
    let uuid;

    return {
        oninit: function () {
            uuid = UuidGenerator.uuidv4();
        },
        oncreate: function (vnode) {
            if (vnode.attrs.file) {
                $('.custom-file-label', $(vnode.dom)).addClass('selected').html(vnode.attrs.file.name);
                $(vnode.dom).find('input').removeAttr('required');
            }
        },
        view: function (vnode) {
            let onFileSelected = function (e) {
                if (e.target.files && e.target.files[0]) {
                    if (e.target.files[0].size > vnode.attrs.maxFileSize) {
                        sizeError = (_t('image.validation.filesize', {maxFileSize: vnode.attrs.maxFileSize/1024/1024})).children + ' ' + e.target.files[0].name;
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        sizeError = null;
                        if (typeof vnode.attrs.index !== 'undefined') {
                            vnode.attrs.onChange(vnode.attrs.index, e.target.files[0]);
                        } else {
                            vnode.attrs.onChange(e.target.files[0]);
                        }
                        if (typeof vnode.attrs.fileName === 'undefined' && vnode.attrs.fileName === null) {
                            $('.custom-file-label', $(vnode.dom)).addClass('selected').html(e.target.files[0].name);
                        }
                    }
                }
            };
            let getSizeError = function () {
                return sizeError;
            };

            let required = typeof vnode.attrs.required !== 'undefined' ? vnode.attrs.required : 'true';

            return (
                <div className="form-group custom-file-container">
                    <div className="custom-file h-100">
                        <input type="file" required={required} className="form-control custom-file-input" accept={vnode.attrs.acceptedTypes} onchange={(e) => onFileSelected(e)} id={uuid}/>
                        <label className="custom-file-label d-flex align-items-center shadow-none h-100" htmlFor={uuid}>{typeof vnode.attrs.fileName !== 'undefined' && vnode.attrs.fileName !== null ? vnode.attrs.fileName : _t('imagefileselector.label')}</label>
                        {getSizeError() ? <div className="text-danger text-uppercase text-right font-size-smallest mt-0">{getSizeError()}</div> : ''}
                        <InvalidFeedbackMessage message={_t('form.errormessage.empty')}/>
                    </div>
                </div>
            );
        },
    };
}
