import XhrUtilities from '../../../extentions/XhrUtilities';

let CustomPhotoModel = function (articleNumber, url, order) {

    let localFile = '';
    let error = '';
    let errorMessage = '';
    let self = this;
    let uploaded = false;
    let cropper;
    let photoUrl = url;
    let isPreviewMode = true;

    this.canSubmit = function () {
        return !! localFile;
    };

    this.canCrop = function () {
        return !! localFile;
    };

    this.getSelectText = function () {
        return localFile ? localFile.name : 'photo.cta.select';
    };

    this.getUrl = function () {
        return photoUrl;
    };

    this.getImageFile = function () {
        return localFile;
    };

    this.setLocalFile = function (file) {
        localFile = file;
    };

    this.getError = function () {
        return error;
    };

    this.setError = function (value) {
        error = value;
    };

    this.getErrorMessage = function () {
        return errorMessage;
    };

    this.setErrorMessage = function (value) {
        errorMessage = value;
    };

    this.setCropper = function (value) {
        cropper = value;
    };

    this.isPreviewMode = function () {
        return isPreviewMode;
    };

    this.setPreviewMode = function (value) {
        isPreviewMode = value;
    };

    this.getMaxPhotoSize = function () {
        return window.config.photoUpload.maxUploadSize;
    };

    this.getPhotoFileTypes = function () {
        return window.config.photoUpload.allowedMimeTypes;
    };

    this.onFileSelected = function (e) {
        let fileName = '';
        if (e.target.value) {
            fileName = e.target.value.split('\\').pop();
        }
        if (fileName && fileName.match(/\.jpg$|\.jpeg$|\.gif$|\.png$/i)) {
            if (e.target.files[0].size > self.getMaxPhotoSize()) {
                self.setError('photo.validation.filesize');
            } else {
                self.setError('');
                self.setLocalFile(e.target.files[0]);
            }
        }
    };

    this.isUploaded = function () {
        return uploaded;
    };

    function getFormData() {
        var data = new FormData();
        var rect = cropper ? cropper.getTargetRectangle() : {};

        data.append('file', localFile);
        data.append('crop', JSON.stringify(rect));

        return data;
    }

    this.submit = function () {
        uploaded = false;
        return m.request({
            background: true,
            method: 'POST',
            url: '/picture/:uid/:article',
            params: {uid: order.getUID(), article: articleNumber},
            body: getFormData(),
            serialize: function(data) {return data;},
            config: XhrUtilities.configHttpRequest,
            extract: XhrUtilities.extractResponse,
        }).then(
            function(response) {
                if (response.status === 201) {
                    uploaded = true;
                    error = localFile = '';
                    photoUrl = response.body;
                    order.setKey(order.getKey() + 1);
                } else {
                    uploaded = false;
                    errorMessage = response.body ? response.body : XhrUtilities.getGeneralErrorMessage();
                }
            },
        );
    };

};

export default CustomPhotoModel;
