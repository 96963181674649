let Translator = function(translations, languages) {
    'use strict';

    let currentLanguage = 'sv';
    translations = translations || {};
    languages = languages || [currentLanguage];

    let substitute = function(string, substitutions) {
        substitutions = substitutions || {};
        for(var key in substitutions) {
            string = string.replace('%' + key + '%', substitutions[key]);
        }
        return string;
    };

    this.setTranslations = function(aData) {
        translations = aData;
    };

    this.setLanguages = function(aData) {
        languages = aData;
    };

    this.getLanguages = function() {
        return languages;
    };

    this.getLanguage = function() {
        return currentLanguage;
    };

    this.setLanguage = function(language) {
        currentLanguage = language;
    };

    this.translate = function(string, substitutions) {
        if (typeof translations[currentLanguage] !== 'undefined') {
            if (typeof translations[currentLanguage][string] !== 'undefined') {
                string = translations[currentLanguage][string];
            } else if (typeof translations[currentLanguage][string.ucfirst()] !== 'undefined') {
                string = translations[currentLanguage][string.ucfirst()].lcfirst();
            } else if (typeof translations[currentLanguage][string.lcfirst()] !== 'undefined') {
                string = translations[currentLanguage][string.lcfirst()].ucfirst();
            }
        }
        return m.trust(substitute(string, substitutions));
    };

    this.getTranslation = function(_translations) {
        if (typeof _translations == 'string') {
            return m.trans.translate(_translations);
        }
        if (typeof _translations[currentLanguage] !== 'undefined') {
            return _translations[currentLanguage];
        }
        if (typeof _translations['en'] !== 'undefined') {
            return _translations['en'];
        }
        if (typeof _translations['sv'] !== 'undefined') {
            return _translations['sv'];
        }
        return (Object.keys(_translations).length ? _translations[Object.keys(_translations)[0]] : '');
    };

    if (! String.prototype.ucfirst) {
        String.prototype.ucfirst = function() {
            return this.charAt(0).toUpperCase() + this.slice(1);
        };
    }

    if (! String.prototype.lcfirst) {
        String.prototype.lcfirst = function() {
            return this.charAt(0).toLowerCase() + this.slice(1);
        };
    }
};

export default Translator;
