import ForhatsHeader from './row/ForhatsHeader';
import ForhatsRow from './row/ForhatsRow';
import HatRowVariant from './row/HatRowVariant';
import HatRow from './row/HatRow';
import OthersRow from './row/OthersRow';
import Bootstrap from '../../../extentions/Bootstrap';

export default function() {
    let rowSetStart = [
        {subtype: 'hats', view: HatRow},
        {subtype: 'hats', view: ForhatsHeader},
    ];

    const rowSetInside = [
        {subtype: 'forhats', view: ForhatsRow},
    ];
    const rowSetEnd = [
        {subtype: ['clothes', 'forclothes', 'accessories'], view: OthersRow},
        {subtype: 'other', view: OthersRow},
        {subtype: 'discount', view: OthersRow},
    ];

    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            if (order.isTherePriceForVar2VariantSubtypeHatsForAnyRow() && rowSetStart.length < 3) {
                rowSetStart.splice(1, 0, {subtype: 'hats', view: HatRowVariant});
            }
            return (
                <div className="row mt-1 mb-1">
                    <div className="col-12 order-list-col">
                        <div className="order-list cart-style">
                            <div className={(Bootstrap.isTabletView() ? 'p-2' : 'p-3')}>
                                <h2>{_t('order.header.details')}</h2>
                                <table className="table m-0">
                                    <tbody>
                                        {rowSetStart.map(function (set) {
                                            return order.getRowsByType(set.subtype).map(function (row, index) {
                                                // eslint-disable-next-line mithril/jsx-key
                                                return <set.view order={order} row={row} index={index} />;
                                            });
                                        })}
                                        <tr>
                                            <td className="border-0 py-0" colspan="3">
                                                <div className="w-100 pl-2">
                                                    <table className="table variants-table mb-0">
                                                        {rowSetInside.map(function (set) {
                                                            return order.getRowsByType(set.subtype).map(function (row, index) {
                                                                // eslint-disable-next-line mithril/jsx-key
                                                                return <set.view order={order} row={row} index={index} />;
                                                            });
                                                        })}
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        {order.getRowsByType('packages').map(function (row, index) {
                                            // eslint-disable-next-line mithril/jsx-key
                                            return <OthersRow order={order} row={row} index={index} />
                                        })}
                                        {order.getRowsByType('packages').map(function (row, index) {
                                            // eslint-disable-next-line mithril/jsx-key
                                            return <tr>
                                                <td className="border-0 py-0" colSpan="3">
                                                    <div className="w-100 pl-2">
                                                        <table className="table variants-table mb-0">
                                                            {order.getPackageRow(row).map(function (row, index) {
                                                                // eslint-disable-next-line mithril/jsx-key
                                                                return <ForhatsRow order={order} row={row} index={index}/>;
                                                            })}
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>;
                                        })}
                                        {rowSetEnd.map(function (set) {
                                            return order.getRowsByType(set.subtype).map(function (row, index) {
                                                // eslint-disable-next-line mithril/jsx-key
                                                return ! order.isRowInPackage(row) ? <set.view order={order} row={row} index={index} /> : [];
                                            });
                                        })}
                                        <tr>
                                            <td className="w-65">
                                                <div>
                                                    <span className="col-12 d-block font-bold text-uppercase sum font-color-primary">{_t('order.footer.summ')}</span>
                                                    <span className="col-12 d-block font-bold sum-total text-uppercase">{_t('order.footer.total')}</span>
                                                </div>
                                            </td>
                                            <td className="w-25" />
                                            <td className="text-right w-10">
                                                <div>
                                                    <span className="col-12 d-block font-bold sum font-color-primary">{order.getListedSum() - order.getSavings()}</span>
                                                    <span className="col-12 d-block font-bold sum-total">{order.getSavings()}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    };
}
