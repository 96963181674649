import PostponeDialog from '../invoice/PostponeDialog';
import OrderStatusButton from '../../../../widgets/OrderStatusButton';
import Bootstrap from '../../../../extentions/Bootstrap';

export default function () {
    let postponeDialog;
    return {
        view: function (vnode) {
            let order = vnode.attrs.order;
            return (
                <div className={Bootstrap.isMobileView() ? 'w-100' : ''}>
                    <OrderStatusButton onClick={() => postponeDialog.show(order)} message={_t('order.cta.postpone')}/>
                    <PostponeDialog oncreate={function (vnode) {
                        postponeDialog = vnode.state;
                    }} order={order}/>
                </div>
            );
        },
    };
}
