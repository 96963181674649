export default function () {
    return {
        view: function (vnode) {
            return (
                <div className="w-100">
                    <div className="w-100">
                        <p className="font-color-title font-size-large font-bold">
                            {vnode.attrs.text}
                        </p>
                    </div>
                    <div className="w-100 d-flex align-items-center">
                        <div className="icon-email"/><div className="flex-fill pl-1"><p className="font-color-title font-size-large font-bold m-0">{_t('support.email')}</p></div>
                    </div>
                    <div className="w-100 d-flex align-items-center pt-1">
                        <div className="icon-phone"/>
                        <div className="flex-fill pl-1">
                            <p className="font-color-title font-size-large font-bold m-0">{_t('support.phone')}</p>
                            <p className="font-size-small font-color-middle-grey m-0">
                                {_t('support.workinghours')}
                            </p>
                        </div>
                    </div>
                </div>
            );
        },
    };
}
