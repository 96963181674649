export default function() {
    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            let logout = function () {
                document.body.classList.remove('hide-scroll');
                order.unload();
            };
            return (
                <a onclick={() => logout()} className="btn btn-secondary-abc text-uppercase font-size-small h-100 d-flex align-items-center justify-content-center">
                    <span className="d-inline-block icon-logout icon-margin-right" />
                    <span className="d-inline-block">{_t('header.cta.logout')}</span>
                </a>
            );
        },
    };
}
