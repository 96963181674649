import HomeNav from './header-mobile/HomeNav';
import CSNav from './header-mobile/CSNav';
import ShopNav from './header-mobile/ShopNav';
import LogoutButton from './header-mobile/Logout';
import Logo from './header-mobile/Logo';
import Bootstrap from '../extentions/Bootstrap';

export default function() {
    return {
        view: function(vnode) {
            let order = vnode.attrs.order;
            this.manageOverflow = function () {
                let el = document.body;
                const cssClass = 'hide-scroll';
                if (Bootstrap.isScrollShown(el)) {
                    if (!el.classList.contains(cssClass)) {
                        el.classList.add(cssClass);
                    } else {
                        el.classList.remove(cssClass);
                    }
                }
            };

            return (
                <header className="row fixed-top">
                    <div className="w-100 header-mobile">
                        <nav className="navbar nav-background px-1">

                            <Logo />

                            <button className="mr-2 navbar-toggler" type="button" data-toggle="collapse" data-target=".collapsible-navbar" onclick={() => this.manageOverflow()}>
                                <span className="d-inline-block align-middle icon-menu"/>
                            </button>

                            <div className="collapsible-navbar collapse navbar-collapse screen-size">
                                <ul className="navbar-nav">
                                    <HomeNav />
                                    <CSNav />
                                </ul>

                                <div className="mx-2 mb-2 button-container-m fixed-bottom">
                                    { order.isLoaded() ? (<LogoutButton order={order}/>) : (<ShopNav />)}
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
            );
        },
    };
}
