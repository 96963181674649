import ModalDialog from '../../../../widgets/ModalDialog';

export default function () {
    let ContainerDialog;
    let errorMessage;
    return {
        oninit: function () {
            ContainerDialog = new ModalDialog();
        },
        show: function (item) {
            errorMessage = item;
            ContainerDialog.show(this);
        },
        view: function (vnode) {
            if (! ContainerDialog.isVisible()) {
                return '';
            }
            let header = _t('deleteorder.error.header');
            let body = <span className="font-color-title font-size-large font-bold">{_t(errorMessage ? errorMessage : 'deleteorder.error.text')}</span>;
            let cancelContent = <span>{_t('message_0052')}</span>;
            let iconClass = 'icon-error';
            return (
                <ContainerDialog hideScroll={vnode.attrs.hideScroll} iconClass={iconClass} outsideClickClose={true} canClose={true} onCancel={vnode.attrs.showScroll}
                    header={header} body={body} cancelContent={cancelContent}/>
            );
        },
    };
}
