import Cropper from 'cropperjs';
import Bootstrap from '../extentions/Bootstrap';

let ImageCropper = function () {
    let instance;
    let reader;

    let options = {
        aspectRatio: 1,
        autoCropArea: 0.65,
        strict: true,
        guides: false,
        highlight: false,
        dragCrop: true,
        cropBoxMovable: true,
        cropBoxResizable: true,
        zoomable: false,
        rotatable: false,
        mouseWheelZoom: false,
        touchDragZoom: false,
        minCropBoxWidth: Bootstrap.isTabletView() ? 64 : 342,
        minCropBoxHeight: Bootstrap.isTabletView() ? 64 : 342,
        viewMode: 1,
        responsive: true,
    };

    function remove() {
        instance && instance.destroy();
        instance = undefined;
    }

    function wrap(vnode, full) {
        options.autoCropArea = full ? 1 : 0.65;
        setTimeout(function () {
            remove();
            instance = new Cropper(vnode.dom, options);
        }, 150);
    }

    return {
        getTargetRectangle: function () {
            let data = {};
            if (instance) {
                let crop = instance.getData(true);
                data.x = crop.x * crop.scaleX;
                data.y = crop.y * crop.scaleY;
                data.width = crop.width * crop.scaleX;
                data.height = crop.height * crop.scaleY;
            }
            return data;
        },
        oninit: function () {
            reader = new FileReader();
        },
        oncreate: function (vnode) {
            reader.addEventListener(
                'load',
                function () {
                    vnode.attrs.src = vnode.dom.src = reader.result;
                    wrap(vnode);
                },
                false,
            );
            if (vnode.attrs.imageFile) {
                reader.readAsDataURL(vnode.attrs.imageFile);
            } else {
                vnode.attrs.src && wrap(vnode, true);
            }
        },
        onremove: remove,
        view: function (vnode) {
            return (
                <img className="d-block w-100"/>
            );
        },
    };
};

export default ImageCropper;
