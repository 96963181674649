export default function() {
    return {
        view: function() {
            return (
                <div className="pl-3">
                    <a target="_blank" className="text-uppercase text-nowrap link-color" href={window.config.companySiteLink}>
                        <span className="d-inline-block icon-arrow-back icon-margin-right" />
                        <span>{_t('header.cta.home')}</span>
                    </a>
                </div>
            );
        },
    };
}
