import OrderStatusType from './OrderStatusType';
import StatusUnlocked from '../../../component/page/order/status/StatusUnlocked';
import StatusLocked from '../../../component/page/order/status/StatusLocked';

let OrderStatusTypeComponentMap = {};

OrderStatusTypeComponentMap[OrderStatusType.LOCKED] = StatusUnlocked;
OrderStatusTypeComponentMap[OrderStatusType.UNLOCKED_INVOICED] = StatusLocked;
OrderStatusTypeComponentMap[OrderStatusType.LOCKED_PURCHASED] = StatusLocked;
OrderStatusTypeComponentMap[OrderStatusType.LOCKED_DELIVERED] = StatusLocked;
OrderStatusTypeComponentMap[OrderStatusType.UNLOCKED] = StatusUnlocked;

export default OrderStatusTypeComponentMap;
