import UuidGenerator from '../../extentions/UuidGenerator';
import XhrUtilities from '../../extentions/XhrUtilities';

let LostLoginFormModel = function () {
    let email;
    let formName = UuidGenerator.uuidv4();
    let submittedFormValid;

    this.setEmail = function (value) {
        email = value;
    };

    this.getEmail = function () {
        return email;
    };

    this.getFormName = function () {
        return formName;
    };

    this.isSubmittedFormValid = function () {
        return submittedFormValid;
    };

    this.addSubmitListener = function (form) {
        form.addEventListener('submit', function(event) {
            event.preventDefault();
            event.stopPropagation();

            submittedFormValid = form.checkValidity();
            form.classList.add('was-validated');
        }, false);
    };

    this.submit = function () {
        return m.request({
            method: 'POST',
            url: '/sc/lostlogin/:email',
            params: {email: email},
            config: XhrUtilities.configHttpRequest,
            extract: XhrUtilities.extractResponse,
        });
    };
};

export default LostLoginFormModel;
